import React, {  useState,useEffect } from "react";
import {  AiFillHeart,  AiOutlineHeart,  AiOutlineMessage,  AiOutlineShoppingCart,} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { backend_url, server } from "../../server";
import styles from "../../styles/styles";
import { Box, useTheme , alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import MoneyIcon from '@mui/icons-material/Money';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import { socket, connectSocket } from "../../socket";
import { AddDirectConversation, UpdateDirectConversation } from "../../redux/slices/conversation";
import { SelectConversation } from "../../redux/slices/app";



const LIST = ({ text, value ,Icon })=>{

  const [secondary, setSecondary] = React.useState(true);


  return(
    <ListItem>
      <ListItemAvatar>
           <Icon /> 
      </ListItemAvatar>
      <ListItemText
       sx={{ margin: '0px 16px' }}
       primary={text}
      />
       <ListItemText
       primary={value}
      />
   </ListItem>
  )
}



const ProductDetails = ({ data }) => {
  const [dense, setDense] = useState(false);
  const {user_id ,type}= useSelector(state=>state.auth)
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [Data, setData] = useState([])
  const [select, setSelect] = useState(0);
  const theme = useTheme();
  const { t} = useTranslation();
  const icons = [MoneyIcon, PriceCheckIcon, HourglassTopIcon, AirportShuttleIcon];
  const texts = [t('priceTotal'), t('originalPrice'), t('WarrantPeriod'), t('deliveryTerm')];
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
   
  if (!socket) {
    connectSocket(user_id ,type);
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
 

  const { conversations} = useSelector(
    (state) => state.conversation.direct_chat
  );

  useEffect(()=>{
    console.log(Data)
    const existing_conversation = conversations.find(
      (el) => el?.id === Data._id
    );
    if (existing_conversation) {
      navigate('/chatApp/app')
    } else if(Data.length > 0) {
      dispatch(AddDirectConversation({ conversation: Data }));
      dispatch(SelectConversation({ room_id: Data._id }));
      //navigate('/chatApp/app')
    }



  },[Data])
  socket.on("start_chat", (data) => {
    setData(data)
 });

  const handleMessageSubmit = async ()=>{
    if(data?.shop)
    socket.emit("start_conversation", { to:data?.shop, from:user_id});
  }

  


  return (
    <Box>
      {data ? (
        <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5 end">
            <Box
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? "#F0F4FA"
                        : theme.palette.background.paper,
            
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                  }}
            
            className="block w-full p-3 800px:flex rounded-[20px]">
              <div className="w-full mr-3 items-center relative  800px:w-[50%] ">
                       <div class="p-[2px auto] flex justify-center w-full items-center">
                       <img
                           src={`${backend_url}${data && data.images[select]}`}
                           alt=""
                           className="max-h-[600px] items-center"
                         />
                      </div>
                <div className="w-full flex items-center justify-center absolute bottom-0">
                  {data &&
                    data.images.map((i, index) => (
                      <div className={`rounded-[3px]`} key={index} >
                        <img
                          src={`${backend_url}${i}`}
                          alt=""
                          className="h-[40px] rounded-[3px] overflow-hidden m-2 "
                          onClick={() => setSelect(index)}
                        />
                      </div>
                    ))}
                  <div
                    className={`${
                      select === 1 ? "border" : "null"
                    } cursor-pointer`}
                  ></div>
                </div>
              </div>
              <div className="w-full 800px:w-[50%] pt-5">
                <h1 className={`${styles.productTitle}`}>{data.name}</h1>
                <p>{data.description}</p>
              
                <div className="flex items-center mt-12 justify-between pr-3">
                <Grid item xs={12} md={6}>
                     <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                       {t('productDetails')}
                     </Typography>
                     <Demo>
                       <List dense={dense}>
                        <LIST
                         text={texts[0]}
                        value = {data?.priceTotal}
                          Icon ={icons[0]}
                        />
                           <LIST
                          text={texts[1]}
                          value= {data?.originalPrice}
                          Icon ={icons[1]}
                        />
                           <LIST
                          text={texts[2]}
                          value= {data?.WarrantPeriod}
                          Icon ={icons[2]}
                        />
                           <LIST
                         text={texts[3]}
                        value= {data.deliveryTerm}
                          Icon ={icons[3]}
                        />
                       </List>
                     </Demo>
                 </Grid>


                  <div>
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() =>{ }}
                        color={click ? "red" : "#333"}
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() =>{}}
                        color={click ? "red" : "#333"}
                        title="Add to wishlist"
                      />
                    )}
                  </div>
                </div>
            {user?.firstname && <Box
                 style={{
                   background: theme.palette.mode === "light"
                    ? alpha(theme.palette.primary.main, 0.5)
                    : theme.palette.primary.main ,
                }} 
                  className={`${styles.button} !mt-6 !rounded !h-11 flex items-center`}
                  onClick={() => navigate('/checkout')}
                >
                  <span className="text-white flex items-center">
                    {t("addToCart")} <AiOutlineShoppingCart className="ml-1" />
                  </span>
                </Box>}
                <div className="flex items-center pt-8">
        
                    <img
                      src={`${backend_url}${data?.shopId?.avatar}`}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full mr-2"
                    />
                
                  <div className="pr-8">
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <h3 className={`${styles.shop_name} pb-1 pt-1`}>
                        {data.shopId?.firstname} {data.shopId?.lastname}
                      </h3>
                    </Link>
                    <h5 className="pb-3 text-[15px]">
                      (5/5) {t('Ratings')}
                    </h5>
                  </div>
                  {user?.firstname && <Box
                 style={{
                   background: theme.palette.mode === "light"
                    ? alpha(theme.palette.primary.main, 0.5)
                    : theme.palette.primary.main ,
                   }} 
                    className={`${styles.button} bg-gradient mt-4 !rounded !h-11`}
                    onClick={handleMessageSubmit}
                  >
                    <span className="flex items-center">
                    {t("sendMessage")}<AiOutlineMessage className="ml-1" />
                    </span>
                  </Box>}
                </div>
              </div>
            </Box>
          </div>
          <br />
          <br />
        </div>
      ) : null}
    </Box>
  );
};


export default ProductDetails;
