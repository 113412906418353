import React from "react";
import { AiOutlineFolderAdd, AiOutlineGift } from "react-icons/ai";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { AiOutlineLogin } from "react-icons/ai";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { useTheme ,alpha} from "@mui/material/styles";
import { Avatar, Box} from "@mui/material";
import { useSelector } from "react-redux";
import { backend_url } from "../../../server";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { LogoutUser } from "../../../redux/slices/auth";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const DashboardSideBar = ({ active }) => {
  const theme = useTheme();
  const navigate = useNavigate()
  const { seller } = useSelector((state) => state.seller);
  const { t } = useTranslation();
  const dispatch = useDispatch()
  
  const logoutHandler = async () => {
    dispatch({
      type:'SellerLOGOUT'
    })
   dispatch(LogoutUser())
    navigate('/')
  };


  return (
    <Box     sx={{
      position: "relative",
      height: "100%",
      minHeight:"100vh",
      backgroundColor:
        theme.palette.mode === "light"
          ? "#F8FAFF"
          : theme.palette.background.paper,
      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
 
    }}
    
    className="w-full shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">
      <div  
              className="w-full flex items-center  rounded-[1rem] mb-10  p-4">
        <Link to={`/dashboard`} className="w-full flex items-center">
        
          <StyledBadge 
          
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
          <Avatar  size={30}  alt={seller.firstname} src={`${backend_url}${seller.avatar}`} />
      </StyledBadge>

          <h5
            className={`hidden 800px:block pl-2 mx-1 text-[18px] font-[400] `}
          >
          {seller.firstname}  {seller.lastname}
          </h5>
        </Link>
      </div>
      <div  style={{
                background: active === 1
                ? theme.palette.mode === "light"
                  ? alpha(theme.palette.primary.main, 0.5)
                  : theme.palette.primary.main : "",
              }}   
              className="w-full flex items-center  rounded-[1rem]  p-4">
        <Link 
          
        className="w-full flex items-center  rounded-[1rem]"
        to="/dashboard" >
          <RxDashboard
            size={30}
          />
          <h5
            className={`hidden 800px:block pl-2 mx-1 text-[18px] font-[400]    `}
          >
            {t("dashboard")}
          </h5>
        </Link>
      </div>
      <div  style={{
                background: active === 4
                ? theme.palette.mode === "light"
                  ? alpha(theme.palette.primary.main, 0.5)
                  : theme.palette.primary.main : "",
              }}   
              className="w-full flex items-center  rounded-[1rem]  p-4">
        <Link
          to="/dashboard-create-product"
          className="w-full flex items-center  rounded-[1rem]"
  
        >
          <AiOutlineFolderAdd
            size={30}
          />
          <h5
            className={`hidden 800px:block mx-1  pl-2 text-[18px] font-[400] `}
          >
            {t("createService")}
          </h5>
        </Link>
      </div>
      <div  style={{
                background: active === 3
                ? theme.palette.mode === "light"
                  ? alpha(theme.palette.primary.main, 0.5)
                  : theme.palette.primary.main : "",
              }}   
              className="w-full flex items-center  rounded-[1rem]  p-4">
        <Link to="/dashboard-products"   
                className="w-full flex items-center  rounded-[1rem]"
        
        >
          <FiPackage size={30}/>
          <h5
            className={`hidden 800px:block pl-2 mx-1  text-[18px] font-[400] `}
          >
            {t('allServices')}
          </h5>
        </Link>
      </div>
      <div  style={{
                background: active === 2
                ? theme.palette.mode === "light"
                  ? alpha(theme.palette.primary.main, 0.5)
                  : theme.palette.primary.main : "",
              }}   
              className="w-full flex items-center  rounded-[1rem]  p-4">
        <Link to="/dashboard-orders" 
       
                className="w-full flex items-center  rounded-[1rem]"
        
        >
          <FiShoppingBag
            size={30}
          />
          <h5
            className={`hidden 800px:block pl-2 mx-1  text-[18px] font-[400]`}
          >
            {t('servicesAcceptedByClient')}
          </h5>
        </Link>
      </div>
      <div  style={{
                background: active === 7
                ? theme.palette.mode === "light"
                  ? alpha(theme.palette.primary.main, 0.5)
                  : theme.palette.primary.main : "",
              }}   
              className="w-full flex items-center  rounded-[1rem]  p-4">
        <Link
          to="/dashboard-withdraw-money"
          className="w-full flex items-center"
        >
          <CiMoneyBill
            size={30}
          />
          <h5
            className={`hidden 800px:block pl-2 mx-1  text-[18px] font-[400] `}
          >
            {t('withdrawMoney')}
          </h5>
        </Link>
      </div>

      <div  style={{
                background: active === 8
                ? theme.palette.mode === "light"
                  ? alpha(theme.palette.primary.main, 0.5)
                  : theme.palette.primary.main : "",
              }}   
              className="w-full flex items-center  rounded-[1rem]  p-4">
        <Link to="/chatApp/app" className="w-full flex items-center">
          <BiMessageSquareDetail
            size={30}
          />
          <h5
            className={`hidden 800px:block pl-2 mx-1  text-[18px] font-[400] `}
          >
            {t("shopInbox")}
          </h5>
        </Link>
      </div>

    { /* <div  style={{
                background: active === 9
                ? theme.palette.mode === "light"
                  ? alpha(theme.palette.primary.main, 0.5)
                  : theme.palette.primary.main : "",
              }}   
              className="w-full flex items-center  rounded-[1rem]  p-4">
        <Link to="/dashboard-coupouns" className="w-full flex items-center">
          <AiOutlineGift
            size={30}
          />
          <h5
            className={`hidden 800px:block pl-2 mx-1  text-[18px] font-[400] `}
          >
            {t('discountCodes')}
          </h5>
        </Link>
      </div>
      <div  style={{
                background: active === 10
                ? theme.palette.mode === "light"
                  ? alpha(theme.palette.primary.main, 0.5)
                  : theme.palette.primary.main : "",
              }}   
              className="w-full flex items-center  rounded-[1rem]  p-4">
        <Link to="/dashboard-refunds" className="w-full flex items-center">
          <HiOutlineReceiptRefund
            size={30}
          />
          <h5
            className={`hidden 800px:block pl-2 mx-1  text-[18px] font-[400]`}
          >
            {t('refunds')}
          </h5>
        </Link>
      </div> */
}
      <div  style={{
                background: active === 11
                ? theme.palette.mode === "light"
                  ? alpha(theme.palette.primary.main, 0.5)
                  : theme.palette.primary.main : "",
              }}   
              className="w-full flex items-center  rounded-[1rem]  p-4">
        <Link to="/settings" className="w-full flex items-center">
          <CiSettings
            size={30}
          />
          <h5
            className={`hidden 800px:block pl-2 mx-1  text-[18px] font-[400] `}
          >
            {t('settings')}
          </h5>
        </Link>
      </div>
      <div  style={{
                background: active === 20
                ? theme.palette.mode === "light"
                  ? alpha(theme.palette.primary.main, 0.5)
                  : theme.palette.primary.main : "",
              }}   
              className="w-full flex items-center  rounded-[1rem]  p-4">
        <Link onClick={()=>(logoutHandler())} to="/" className="w-full flex items-center">
          <AiOutlineLogin
            size={30}
          />
          <h5
            className={`hidden 800px:block pl-2 mx-1  text-[18px] font-[400] `}
          >
            {t('Logout')}
          </h5>
        </Link>
      </div>
    </Box>
  );
};

export default DashboardSideBar;
