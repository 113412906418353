import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../server";

const SellerActivationPage = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(false);
  const sendRequest = async () => {
    await axios
      .post(`${server}/shop/activation`, {
        activation_token,
      })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        setError(true);
      });
  };
  useEffect(() => {
    if (activation_token) {

      sendRequest();
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {error ? (
        <p>Your token is expired!</p>
      ) : (
        <p>Your account has been created suceessfully!</p>
      )}
    </div>
  );
};

export default SellerActivationPage;
