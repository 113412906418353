import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
// import S3 from "../../utils/s3";
import {v4} from 'uuid';
import { toast } from "react-toastify";
// ----------------------------------------------------------------------

const initialState = {
  user: {},
  CurrentBenefit:5,
  Video:"",
  language:"ar",
  Balance:0,
  pages:[],
  MobileChat:{
    open:true,
   },
  sideBar: {
    open: false,
    type: "CONTACT", // can be CONTACT, STARRED, SHARED
  },
  isLoggedIn: true,
  tab: 0, // [0, 1, 2, 3]
  snackbar: {
    open: null,
    severity: null,
    message: null,
  },
  users: [], // all users of app who are not friends and not requested yet
  all_users: [],
  friends: [], // all friends
  friendRequests: [], // all friend requests
  chat_type: null,
  room_id: null,
  call_logs: [],
  toggleMobileChat(state) {
    state.MobileChat.open = !state.MobileChat.open;
  },
  CurrentProduct:[],

};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchCallLogs(state, action) {
      state.call_logs = action.payload.call_logs;
    },
    toggleMobileChat(state) {
      state.MobileChat.open = !state.MobileChat.open;
    },
    fetchUser(state, action) {
      state.user = action.payload.user;
    },
    updateUser(state, action) {
      state.user = action.payload.user;
    },
    // Toggle Sidebar
    toggleSideBar(state) {
      state.sideBar.open = !state.sideBar.open;
    },
    updateSideBarType(state, action) {
      state.sideBar.type = action.payload.type;
    },
    updateTab(state, action) {
      state.tab = action.payload.tab;
    },

    openSnackBar(state, action) {
      state.snackbar.open = true;
      state.snackbar.severity = action.payload.severity;
      state.snackbar.message = action.payload.message;
    },
    closeSnackBar(state) {
      state.snackbar.open = false;
      state.snackbar.message = null;
    },
    updateUsers(state, action) {
      state.users = action.payload.users;
    },
    updateAllUsers(state, action) {
      state.all_users = action.payload.users;
    },
    updateFriends(state, action) {
      state.friends = action.payload.friends;
    },
    updateFriendRequests(state, action) {
      state.friendRequests = action.payload.requests;
    },
    selectConversation(state, action) {
      state.chat_type = "individual";
      state.room_id = action.payload.room_id;
    },
    UpdateCurrentProducts(state, action) {
      state.CurrentProduct = action.payload.CurrentProduct;
    },
    UpdateCurrentBenefit(state, action) {
      state.CurrentBenefit = action.payload.Value;
    },
    UpdateSellerBalance(state, action) {
      state.Balance = action.payload.Value;
    },
    UpdateVideo(state, action) {
      state.Video = action.payload.Value;
    },
    UpdatePages(state , action){
      state.pages = action.payload.pages
    }
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function toggleMobileChat() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.toggleMobileChat());
  };
}
export const closeSnackBar = () => async (dispatch, getState) => {
  dispatch(slice.actions.closeSnackBar());
};

export const showSnackbar =
  ({ severity, message }) =>
  async (dispatch, getState) => {
    dispatch(
      slice.actions.openSnackBar({
        message,
        severity,
      })
    );

    setTimeout(() => {
      dispatch(slice.actions.closeSnackBar());
    }, 4000);
  };

export function ToggleSidebar() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.toggleSideBar());
  };
}
export function UpdateSidebarType(type) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateSideBarType({ type }));
  };
}
export function UpdateTab(tab) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateTab(tab));
  };
}
export function GetCurrentProducts(id) {
  console.log(id)
  return async (dispatch, getState) => {
    await axios
      .get(
        `/product/productsById/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
           authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.UpdateCurrentProducts({ CurrentProduct: response.data.products}));
      })
      .catch((err) => {
        dispatch(slice.actions.UpdateCurrentProducts({ CurrentProduct: {}}));
      });
  };
}
export function GetPages() {
  return async (dispatch, getState) => {
    await axios
      .get(
        `/page`,
        {
          headers: {
            "Content-Type": "application/json",
           authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.UpdatePages({ pages: response.data.getAllPages}));
      })
      .catch((err) => {
        toast.error(err.message)
      });
  };
}
export function  GetCurrentBenefit() {
  return async (dispatch, getState) => {
    await axios
      .get(
        `/Benefit`,
        {
          headers: {
            "Content-Type": "application/json",
           authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.UpdateCurrentBenefit({ Value: response.data.value}));
      })
      .catch((err) => {
      });
  };
}
export function GetCurrentBalance(id) {
  return async (dispatch, getState) => {
    await axios
      .get(
        `/shop/SellerBalance/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
             authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.UpdateSellerBalance({ Value: response.data.balance}));
      })
      .catch((err) => {
      });
  };
}
export function GetCurrentVideo() {
  return async (dispatch, getState) => {
    await axios
      .get(
        `/video`,
        {
          headers: {
            "Content-Type": "application/json",
           authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.UpdateVideo({ Value: response.data.value}));
      })
      .catch((err) => {
      });
  };
}
export function FetchUsers() {
  return async (dispatch, getState) => {
    await axios
      .get(
        "/user/get-users",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.updateUsers({ users: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function FetchAllUsers() {
  return async (dispatch, getState) => {
    await axios
      .get(
        "/user/get-all-verified-users",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.updateAllUsers({ users: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function UserUpdateOrder(data) {
  return async (dispatch, getState) => {
    await axios
      .put(
        `order/userUpdateOrder/${data.id}`,
        {type:data.type},

        {
          headers: {
            "Content-Type": "application/json",
             Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message)
        dispatch(slice.actions.updateFriends({ friends: response.data.data }));
      })
      .catch((err) => {
        toast.error(err?.message)
      });
  };
}
export function FetchFriendRequests() {
  return async (dispatch, getState) => {
    await axios
      .get(
        "/user/get-requests",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          slice.actions.updateFriendRequests({ requests: response.data.data })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export const SelectConversation = ({ room_id }) => {
  console.log(room_id)
  return async (dispatch, getState) => {
    dispatch(slice.actions.selectConversation({ room_id }));
  };
};

export const FetchCallLogs = () => {
  return async (dispatch, getState) => {
    axios
      .get("/user/get-call-logs", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.fetchCallLogs({ call_logs: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const FetchUserProfile = () => {
  return async (dispatch, getState) => {
    axios
      .get("/user/get-me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.fetchUser({ user: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const UpdateUserProfile = (formValues) => {
  return async (dispatch, getState) => {
    const file = formValues.avatar;

    const key = v4();

    try{

    }
    catch(error) {
      console.log(error);
    }

    

    axios
      .patch(
        "/user/update-me",
        { ...formValues, avatar: key },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.updateUser({ user: response.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
