import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector } from 'react-redux';
import { backend_url } from '../server';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from 'react-i18next';
import {payment} from "../assets"



const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CartCategory() {
    const { t} = useTranslation();

  const [expanded, setExpanded] = React.useState(false);
  const Categories= useSelector(state=>state.category)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
      <>
      <h1 className="flex-1  font-Cairo font-bold ss:text-[50px] mb-4 text-[40px] gray ss:leading-[100.8px] leading-[75px]">
       {t('category')} 
     </h1>

          <div className='items-center'>

         <Carousel
  responsive={{
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }}
  infinite
  autoPlay
  autoPlaySpeed={3000}
>
    
     {!Categories.isLoading && Categories?.Categories?.map((e , k)=>{
         return ( 
          <div  key={k} className="relative mx-auto  max-w-[200px] max-h-[345px] [200px] h-[345px] overflow-hidden ">
            <h1 className={"absolute top-0 left-10 font-Cairo font-bold"}>{e.name.toUpperCase()} </h1> 
            <img src={`${backend_url}${e.img}`} alt={`${e.img}`} className='h-[345px] max-w-[200px] w-[200px] max-h-[345px]' />
          </div>

)
      })}
        </Carousel>
          </div>
     </>

 
  );
}