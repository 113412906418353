import { Link } from "react-router-dom";
import { features } from "../contexts";
import styles, { layout } from "../style";
import Button from "./Button";
import { useTranslation } from "react-i18next";


const FeatureCard = ({ icon, title, content, index }) => (
  <div className={`flex flex-row p-6 rounded-[20px] hover:text-white sm:min-w-[600px] ${index !== features.length - 1 ? "mb-6" : "mb-0"} feature-card`} >
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}>
      <img src={icon} alt="star" className="w-[50%] h-[50%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3 sm:w-[600px]">
      <h4 className="font-Cairo font-bold text-gradient text-[18px] leading-[23.4px] mb-1">
        {title}
      </h4>
      <p className=" font-normal text-dimWhite text-[16px] leading-[24px]">
        {content}
      </p>
    </div>
  </div>
);

const Business = () =>  {
  
  
  const { t } = useTranslation();

  return (
  <section id="features" className={layout.section}>
    <div className={layout.sectionInfo}>
      <h2 className={`${styles.heading2} text-gradient font-Cairo font-bold`}>
        {t('youDoTheBusiness')}, <br className="sm:block hidden" /> {t('weHandleTheMoney')}
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        {t('toGetStarted')}
      </p>
      <Link to="/auth/sellerLogin">
      <Button styles={`mt-10`} />
      </Link>
    </div>

    <div className={`${layout.sectionImg} flex-col`}>
      {features.map((feature, index) => (
          <div key={index} className={`flex flex-row p-6 rounded-[20px] hover:text-white sm:min-w-[600px] ${index !== features.length - 1 ? "mb-6" : "mb-0"} feature-card`} >
          <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}>
            <img src={feature.icon} alt="star" className="w-[50%] h-[50%] object-contain" />
          </div>
          <div className="flex-1 flex flex-col ml-3 sm:w-[600px]">
            <h4 className="font-Alexandria font-semibold text-gradient text-[18px] leading-[23.4px] mb-1">
            {t(`feature.${index}.title`)}
            </h4>
            <p className="font-Alexandria text-dimWhite text-[16px] leading-[24px]">
            {t(`feature.${index}.content`)}
            </p>
          </div>
        </div>
      ))}
    </div>
  </section>
);
}
export default Business;
