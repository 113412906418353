import axios from "../../utils/axios";

export const getAllCategory = () => {
    return async dispatch => {
        const res = await axios.get(`category/getcategory`);
        if(res.data.categoryList){
            dispatch({
                type: "getAllCategories",
                payload: { categories: res.data.categoryList }
            });
        }else{
            dispatch({
                type:" GET_ALL_CATEGORIES_FAILURE",
                payload: { error: res.data.error }
            });
        }
    }
}
export const getCategoryById = (id) => {
   
        return async dispatch => {
            const res = await axios.get(`/category/${id}`);
            if (res.status === 200) {
                dispatch({
                    type: 'GET_CATEGORY_BY_ID',
                    payload: res.data
                });
            } else {
                // dispatch({
                //     type: 
                // })
            }
        }


    }