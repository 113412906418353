import React, { useEffect } from "react";
import {  AiOutlineMoneyCollect } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { MdBorderClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { getAllProductsShop } from "../../redux/actions/product";
import { Avatar} from "@material-ui/core";
import { Box, useTheme ,alpha} from "@mui/material";
import { useTranslation } from "react-i18next";
import MaterialTable from 'material-table'
import { backend_url } from "../../server";
import moment from "moment/moment";
import { GetCurrentBalance } from "../../redux/slices/app";


const DashboardHero = () => {
  const dispatch = useDispatch();

  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const theme = useTheme();
  const { t} = useTranslation();

  const { Balance } = useSelector((state) => state.app);


  useEffect(() => {
     dispatch(getAllOrdersOfShop(seller._id));
     dispatch(getAllProductsShop(seller._id));
     dispatch(GetCurrentBalance(seller._id));
  }, [dispatch]);

    const GroupsColumns = [
      {  title: t("image"), maxWidth:"60px" ,field: "images",render:(row)=><Avatar src={`${backend_url}${row.images}`}  /> },
    {  title: t("deal"), field:"service",  },
    {  title: t("status"), field: "status", },
    {  title: t("totalPrice"), field:"totalPrice", },
    {  title: t("paidAt"), field:"paidAt", render: (row)=> moment(row.paidAt).format("YYYY MM DD hh:mm") },
    {  title: t("deliveryTerm"), field:"deliveryTerm", },
    {  title: t("WarrantPeriod"), field:"WarrantPeriod", },
    {  title: t("actions"), field:"_id", render:(row)=> <div className='cellAction'> <Link to={`/order/${row?._id}`}  className={`p-2 rounded-md text-center text-gray-900 bg-[#00FF00]`} >{t("view")}</Link> </div>},
     
  ];

  const row = [];

  orders && orders.forEach((item) => {
    row.push({
        _id: item._id,
        images:item?.service?.images?.length && item?.service?.images[0],
        name: item?.service?.name,
        total:  item?.totalPrice+" " + t("dz"),
        status: item?.status,
        service:item?.service?.name,
        paidAt:item.paidAt,
        deliveryTerm:item?.service?.deliveryTerm,
        WarrantPeriod:item?.service?.WarrantPeriod
        ,totalPrice:item?.totalPrice + " " + t("dz")
      });
  });
  return (
    <div className="w-full p-8">
      <h3 className="text-[22px] font-Poppins pb-2">{t('Overview')}</h3>
      
      <div
      className=" block 800px:flex items-center justify-between">
        <Box
           sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? "#F0F4FA"
                : theme.palette.background.paper,
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
          }} 
         
        
        
        
        className="w-full mb-4  text-center 800px:w-[30%] min-h-[20vh]  shadow rounded px-2 py-5">
          <div
          className="flex items-center">
            <AiOutlineMoneyCollect
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] `}
            >
               {t('availableFunds')} {" "} 
            </h3>
          </div>
          <h5 className="pt-2 mx-auto text-center w-full pl-[36px] text-[22px] font-[500]">{Balance} {t("dz")}</h5>

          <Link
   
          to="/dashboard-withdraw-money">
            <h5   
                   style={{
                    backgroundColor: theme.palette.primary.main ,
                  }}
            
            
            className="p-2 mx-auto max-w-[100px] mt-2 rounded-md text-center  ">
              
               {t('withdraw')} {" "}</h5>
          </Link>
        </Box>

        <Box
        sx={{
          backgroundColor:
          theme.palette.mode === "light"
                ? "#F0F4FA"
                : theme.palette.background.paper,
  
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }} 
        
        
           className="w-full mb-4 800px:w-[30%] min-h-[20vh]  text-center shadow rounded px-2 py-5">
          <div className="flex items-center">
            <MdBorderClear size={30} className="mr-2" fill="#00000085" />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400]`}
            >
                            {t('servicesAcceptedByClient')} 

            </h3>
          </div>
          <h5 className="pt-2 mx-auto text-center w-full pl-[36px] text-[22px] font-[500]">{orders && orders.length}</h5>
          <Link to="/dashboard-orders">
            <h5 
            style={{
              backgroundColor: theme.palette.primary.main ,
            }}
      
      
      className="p-2 mx-auto max-w-[100px] mt-2 rounded-md text-center  ">
              
              
              {t('View')}</h5>
          </Link>
        </Box>

        <Box
           sx={{
            backgroundColor:
              
              theme.palette.mode === "light"
                ? "#F0F4FA"
                : theme.palette.background.paper,
    
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
          }} 
        
        className="w-full mb-4 800px:w-[30%]  text-center min-h-[20vh] shadow rounded px-2 py-5">
          <div className="flex items-center">
            <AiOutlineMoneyCollect
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400]`}
            >
                                {t('servicesAcceptedByAdmin')}

            </h3>
          </div>
          <h5 
          className="pt-2 mx-auto text-center w-full pl-[36px] text-[22px] font-[500]">
            
            
            {products && products.length}</h5>
          <Link to="/dashboard-products">
            <h5
          style={{ backgroundColor: theme.palette.primary.main ,  }}
         className="p-2 mx-auto max-w-[120px] mt-2 rounded-md text-center  ">
              {t('viewServices')}</h5>
          </Link>
        </Box>
        
      </div>

      <br />
      <Box
         sx={{
          backgroundColor:
          theme.palette.mode === "light"
          ? "#F0F4FA"
          : theme.palette.background.paper,
          color: 
          theme.palette.mode === "light" && "#fff",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }} className="w-full min-h-[45vh] rounded xs-max-w-[300px] overflow- ">

          { orders &&
      <MaterialTable columns={GroupsColumns} data={row} title={t("latestOrders")}
      options={{exportButton:true,actionsColumnIndex:-1,addRowPosition:"first" , columnsButton:true, }}
  />

            
          }
      </Box>
    </div>
  );
};

export default DashboardHero;
