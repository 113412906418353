import styles from "../style";
import { LOGO} from "../assets";
import {  socialMedia } from "../contexts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material";

const Footer = () => {
  const {pages} = useSelector((state)=>state.app)
  const theme = useTheme()

  const {t} = useTranslation()
  
  return(
  <section 
  
  style={{

      backgroundColor:
        theme.palette.mode === "light"
          ? "#F8FAFF"
      : theme.palette.background.paper,
  }}
  className={`${styles.flexCenter} ${styles.paddingY} flex-col px-6`}>
    <div className={`${styles.flexStart} md:flex-row flex-col mb-8 w-full`}>
      <div className="flex-[1] flex flex-col justify-start mr-10">
        <img
          src={LOGO}
          alt="escrawme"
          className="w-[266px] h-[72.14px] object-contain"
        />
              <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          {t('subtitle')}        
            </p>
      </div>
     <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10">
         { pages &&  pages.map((footerlink) => (
          <Link key={footerlink.title} to={`/footer?type=${footerlink.slug}`}>
          <div key={footerlink.title} className={`flex flex-col ss:my-0 my-4 min-w-[150px]`}>
            <h4 className="font-poppins font-medium text-[18px] leading-[27px] ">
              {footerlink.title}
            </h4>
          </div>

          </Link>
        ))}
                </div> 
    </div>

    <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]">
      <p className="font-poppins font-normal text-center text-[18px] leading-[27px] ">
        Copyright Ⓒ 2023 Escrowme. All Rights Reserved.
      </p>

      <div className="flex flex-row md:mt-0 mt-6">
        {socialMedia.map((social, index) => (
          <img
            key={social.id}
            src={social.icon}
            alt={social.id}
            className={`w-[21px] mx-4 h-[21px] object-contain cursor-pointer`}
            onClick={() => window.open(social.link)}
          />
        ))}
      </div>
    </div>
  </section>
);}

export default Footer;
