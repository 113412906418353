import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const categoryReducer = createReducer(initialState, {
  getAllCategories: (state,action) => {
    state.isLoading=false
    state.Categories = action.payload.categories;
  },
  GET_ALL_CATEGORIES_FAILURE:(state,action)=>{

  }
});
