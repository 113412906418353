import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { Avatar, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaterialTable from "material-table";
import { backend_url } from "../../server";
import moment from "moment";


const AllOrders = () => {
  const theme = useTheme();
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const {t} = useTranslation()

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);


  const GroupsColumns = [
    {  title: t("image"), maxWidth:"60px" ,field: "images",render:(row)=><Avatar src={`${backend_url}${row.images}`}  /> },
  {  title: t("deal"), field:"service",  },
  {  title: t("status"), field: "status", },
  {  title: t("totalPrice"), field:"totalPrice", },
  {  title: t("paidAt"), field:"paidAt", render: (row)=> moment(row.paidAt).format("YYYY MM DD hh:mm") },
  {  title: t("deliveryTerm"), field:"deliveryTerm", },
  {  title: t("WarrantPeriod"), field:"WarrantPeriod", },
  {  title: t("actions"), field:"_id", render:(row)=> <div className='cellAction'> <Link to={`/order/${row?._id}`}  className={`p-2 rounded-md text-center text-gray-900 bg-[#00FF00]`} >{t("view")}</Link> </div>},
   
];
  const row = [];

  orders && orders.forEach((item) => {
    row.push({
        _id: item._id,
        images:item?.service?.images[0],
        name: item?.service?.name,
        total:  item?.totalPrice+ "DZ ",
        status: item?.status,
        service:item?.service?.name,
        paidAt:item.paidAt,
        deliveryTerm:item?.service?.deliveryTerm,
        WarrantPeriod:item?.service?.WarrantPeriod
        ,totalPrice:item?.totalPrice
      });
  });
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 ">
      
      <MaterialTable columns={GroupsColumns} data={row} title={t("latestOrders")}
      options={{exportButton:true,actionsColumnIndex:-1,addRowPosition:"first" , columnsButton:true, }}
      style={{
        backgroundColor:
        theme.palette.mode === "light"
        ? "#F0F4FA"
        : theme.palette.background.paper,
        color:theme.palette.mode === "dark" && "white" ,
        borderRadius:"2rem"

      }}
  />
       
        </div>
      )}
    </>
  );
};

export default AllOrders;
