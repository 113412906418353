import React,{useEffect} from "react";
import "./App.css";
import { Suspense, lazy } from "react";
import DashboardLayout from "./layouts/dashboard";
import AuthLayout from "./layouts/auth";
import { Navigate, useNavigate} from "react-router-dom";
import { DEFAULT_PATH } from "./config";
import LoadingScreen from "./components/LoadingScreen";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {   ActivationPage, HomePage, CheckoutPage,  ProductDetailsPage, ProfilePage,  SellerActivationPage, OrderDetailsPage, TrackOrderPage, } from "./routes/Routes.js";
import { ShopDashboardPage, ShopCreateProduct, ShopAllProducts, ShopAllCoupouns, ShopAllOrders, ShopOrderDetails, ShopAllRefunds, ShopSettingsPage, ShopWithDrawMoneyPage,} from "./routes/ShopRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./routes/ProtectedRoute";
import SellerProtectedRoute from "./routes/SellerProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import ThemeSettings from "./components/settings";
import ThemeProvider from "./theme";
import { Navbar } from "./components";
import {getAllCategory}from "./redux/actions/category"
import {Footer} from "./components/index"
import {GetCurrentBenefit , GetCurrentVideo, GetPages } from "./redux/slices/app";
import ScrollToTop from "./components/ScrollToTop";
import Slug from "./components/Slug";
import useSettings from "./hooks/useSettings";




const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};
const GeneralApp = Loadable(
  lazy(() => import("./pages/dashboard/GeneralApp"))
);

const Page404 = Loadable(lazy(() => import("./pages/Page404")));
const LoginPage = Loadable(lazy(() => import("./pages/auth/Login")));
const LoginPageSeller = Loadable(lazy(() => import("./pages/auth/LoginSeller")));
const VerifyPage = Loadable(lazy(() => import("./pages/auth/Verify")));
const RegisterPage = Loadable(lazy(() => import("./pages/auth/Register")));
const RegisterSeller = Loadable(lazy(() => import("./pages/auth/RegisterSeller")));
const ResetPasswordPage = Loadable(
  lazy(() => import("./pages/auth/ResetPassword"))
);
const NewPasswordPage = Loadable(
  lazy(() => import("./pages/auth/NewPassword"))
);
const ResetPasswordSeller = Loadable(
  lazy(() => import("./pages/auth/ResetPasswordSeller"))
);
const NewPasswordSeller = Loadable(
  lazy(() => import("./pages/auth/NewPasswordSeller"))
);

const Settings = Loadable(lazy(() => import("./pages/dashboard/Settings")));
const App = () => {
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { user_id ,type } = useSelector(
    (state) => state.auth
  );
  const {onChangeDirectionByLang} = useSettings()

 
 useEffect(() => {
  onChangeDirectionByLang(window.localStorage.getItem("language"))
  dispatch(getAllCategory())
  dispatch(GetCurrentBenefit())
  dispatch(GetPages())
  dispatch(GetCurrentVideo())


 }, [])
 
  return (
    <BrowserRouter>
     <ThemeProvider>
      <ThemeSettings>
             <Navbar />
             <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/footer"  element={<Slug /> }/>
        <Route path="/activation/:activation_token"   element={<ActivationPage />} />
        <Route path="/seller/activation/:activation_token"  element={<SellerActivationPage />}/>
        <Route path="/product/:id" element={<ProductDetailsPage />} />
        <Route path="/checkout"  element={user?.firstname ?     <ProtectedRoute>  <CheckoutPage /> </ProtectedRoute> : <Navigate to={"/auth/login"}  />   }/>
        <Route path="/profile"  element={    <ProtectedRoute>  <ProfilePage /> </ProtectedRoute> }/>
        <Route path="/user/order/:id" element={   <ProtectedRoute>     <OrderDetailsPage />   </ProtectedRoute> } />
        <Route   path="/user/track/order/:id"  element={<ProtectedRoute>   <TrackOrderPage /> </ProtectedRoute>   } />
        {/* shop Routes */}
        <Route
          path="/settings"
          element={
            <SellerProtectedRoute>
              <ShopSettingsPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />

       <Route
         path="/ChatApp"
         element={<DashboardLayout />}
       >
         <Route index={true} element={<Navigate to={DEFAULT_PATH} replace />} />
         <Route path="app" element={<GeneralApp />} />
         <Route path="settings" element={<Settings />} />
         <Route path="404" element={<Page404 />} />
       </Route>
       


       
       <Route
         path="/auth"
         element={<AuthLayout />}
       >
         <Route path="login" element={<LoginPage />} />
         <Route path="sellerLogin" element={<LoginPageSeller />} />
         <Route path="register" element={<RegisterPage /> } />
         <Route path="sellerRegister" element={<RegisterSeller /> } />
         <Route path="reset-password" element={<ResetPasswordPage />} />
         <Route path="new-password" element={<NewPasswordPage />} />
         <Route path="seller/reset-password" element={<ResetPasswordSeller />} />
         <Route path="seller/new-password" element={<NewPasswordSeller />} />

         <Route path="verify" element={<VerifyPage />} />
       </Route>
                  
        <Route
          path="/dashboard-create-product"
          element={
            <SellerProtectedRoute>
              <ShopCreateProduct />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-orders"
          element={
            <SellerProtectedRoute>
              <ShopAllOrders />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-refunds"
          element={
            <SellerProtectedRoute>
              <ShopAllRefunds />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/order/:id"
          element={
            <SellerProtectedRoute>
              <ShopOrderDetails />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-products"
          element={
            <SellerProtectedRoute>
              <ShopAllProducts />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/dashboard-coupouns"
          element={
            <SellerProtectedRoute>
              <ShopAllCoupouns />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-withdraw-money"
          element={
            <SellerProtectedRoute>
              <ShopWithDrawMoneyPage />
            </SellerProtectedRoute>
          }
        />
      </Routes>
        <Footer  />
      </ThemeSettings>
      </ThemeProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;
