import React from 'react'
import Checkout from "../components/Checkout/Checkout";
import { useSelector } from 'react-redux';
import { Container, Stack,useTheme } from "@mui/material";
import { Navigate} from "react-router-dom";
import {payment}  from './../assets'


const CheckoutPage = () => {
  const {user} = useSelector(state => state.user)
  const theme = useTheme()


  if(!user.firstname ){
    return <Navigate to={"auth/login"} replace />
   }

  return (
    <Container sx={{ mt: 5 ,
      backgroundColor:
      theme.palette.mode === "light"
        ? "#F0F4FA"
        : theme.palette.background.paper,
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
    }} maxWidth="sm">
    <Stack spacing={5}>
      <Stack
        direction="column"
        alignItems={"center"}
        sx={{
          width: "100%",
        }}
      >
        <img style={{ height: 400, width:"100%" }} src={payment} alt="Logo" />
      </Stack>
      <Checkout />



    </Stack>
  </Container>

  )
}

export default CheckoutPage