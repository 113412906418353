import React from 'react'
import OrderDetails from "../../components/Shop/OrderDetails";

const ShopOrderDetails = () => {
  return (
    <div>
         <OrderDetails />
    </div>
  )
}

export default ShopOrderDetails