import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  AiOutlineMoneyCollect } from "react-icons/ai";
import { MdBorderClear } from "react-icons/md";

import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { loadSeller } from "../../redux/actions/user";
import { AiOutlineDelete } from "react-icons/ai";
import { Box, Button, Divider, useTheme ,alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetCurrentBalance } from "../../redux/slices/app";


const WithdrawMoney = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { seller } = useSelector((state) => state.seller);
  const { Balance } = useSelector((state) => state.app);
  const {orders} = useSelector((state)=>state.order)
  console.log(orders)
  const [paymentMethod, setPaymentMethod] = useState(false);
  const theme = useTheme();
  const { t} = useTranslation();
  dispatch(GetCurrentBalance(seller._id));

  
  
  const calculateTotalPrice = () => {
    const totalPrice = orders
      .filter(order => order.status === "failed")
      .filter(order => order.type === 'non-withdrawable')
      .reduce((accumulator, order) => accumulator + order?.SellerPrice, 0);
  
    return totalPrice;
  };
  const totalPrice = calculateTotalPrice();


  const [bankInfo, setBankInfo] = useState({
    bankName: "",
    bankCountry: "",
    bankSwiftCode: null,
    bankAccountNumber: null,
    bankHolderName: "",
    bankAddress: "",
  });

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const withdrawMethod = {
      bankName: bankInfo?.bankName,
      bankAccountNumber: bankInfo?.bankAccountNumber,
      bankHolderName: bankInfo?.bankHolderName,
    };

    setPaymentMethod(false);
    await axios
      .put(
        `${server}/shop/update-payment-methods`,
        withdrawMethod,
        {
          headers: {
            "Content-Type": "application/json",
             authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        },
      )
      .then((res) => {
        toast.success("Withdraw method added successfully!");
        dispatch(loadSeller(seller._id));
        setBankInfo({
          bankName: "",
          bankAccountNumber: null,
          bankHolderName: "",
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message)
      });
  };

  const deleteHandler = async () => {
    await axios
      .delete(`${server}/shop/delete-withdraw-method`,  {
        headers: {
          "Content-Type": "application/json",
           authorization: `Bearer ${localStorage.getItem('token')}`,
          }
      },)
      .then((res) => {
        toast.success("Withdraw method deleted successfully!");
        dispatch(loadSeller(seller._id));
      });
  };

  const error = () => {
    toast.error("You not have enough balance to withdraw!");
  };

  const withdrawHandler = async () => {
    if ( Balance  < 1000 ) {
      toast.error("You can't withdraw this amount!");
    } else {
      const amount = availableBalance;
      await axios
        .post(
          `${server}/withdraw/create-withdraw-request`,
          { amount },
          {
            headers: {
              "Content-Type": "application/json",
               authorization: `Bearer ${localStorage.getItem('token')}`,
              }
          },
        )
        .then((res) => {
          toast.success("Withdraw money request is successful!");
        }).catch((err)=>{
          toast.error(err.response.data.message)
        });
    }
  };

  const availableBalance = seller?.availableBalance?.toFixed(2);

  return (
    <div className="w-full h-[90vh] p-8">

     <Box
      className=" block 800px:flex items-center justify-center">
        <Box
           sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? "#F0F4FA"
                : theme.palette.background.paper,
    
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
          }} 
          className="w-full mx-2 mb-4 800px:w-[30%] min-h-[20vh]  shadow rounded px-2 py-5">
          <div
          className="flex items-center">
            <AiOutlineMoneyCollect
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] `}
            >
               {t('availableFunds')} {" "} {Balance} {t("dz")}
            </h3>
          </div>
            <h2 className="pt-4 pl-[2] "> </h2>
          <Button  className="p-3 pl-[2] mt-2 mx-auto " variant="outlined" onClick={() => (Balance < 1000 ? error() : setOpen(true))}>
            {t('withdraw')} {" "} {Balance}
          </Button>
        </Box>

        <Box
        sx={{
          backgroundColor:
          theme.palette.mode === "light"
                ? "#F0F4FA"
                : theme.palette.background.paper,
  
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }} 
        className="w-full mx-2 mb-4 800px:w-[30%] min-h-[20vh]  shadow rounded px-2 py-5">
        <div className="flex items-center">
            <MdBorderClear size={30} className="mr-2" fill="#00000085" />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400]`}
            >
             {t('balanceInProcessing')}    <span className="text-[16px]">({t('adminValidation')})</span>

            </h3>
          </div>
          <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">   {totalPrice} {t("dz")} </h5>
        </Box>

      </Box>
      {open && (
        <Box
        sx={{
          backgroundColor:
          theme.palette.mode === "light"
                ? "#F0F4FA"
                : theme.palette.background.paper,
  
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }} 
         className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center ">
          <div
            className={`w-[95%] 800px:w-[50%] shadow rounded ${
              paymentMethod ? "h-[80vh] overflow-y-scroll" : "h-[unset]"
            } min-h-[40vh] p-3`}
          >
            <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpen(false) || setPaymentMethod(false)}
                className="cursor-pointer"
              />
            </div>
            {paymentMethod ? (
              <Box
              sx={{
                backgroundColor:
                theme.palette.mode === "light"
                      ? "#F0F4FA"
                      : theme.palette.background.paper,
        
                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
              }} >
                <h3 className="text-[22px] font-Poppins text-center font-[600]">
                 {t('AddNewWithdrawMethod')}
                </h3>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label>
                      {t('BankName')} 
                    </label>
                    <input
                      type="text"
                      name=""
                      required
                      value={bankInfo.bankName}
                      onChange={(e) =>
                        setBankInfo({ ...bankInfo, bankName: e.target.value })
                      }
                      id=""
                      placeholder="Enter your Bank name!"
                      className={`${styles.input} mt-2`}
                    />
                  </div>
                  <div className="pt-2">
                    <label>
                      {t('BankAccountNumber')} {" "}
                    
                    </label>
                    <input
                      type="number"
                      name=""
                      id=""
                      value={bankInfo?.bankAccountNumber}
                      onChange={(e) =>
                        setBankInfo({
                          ...bankInfo,
                          bankAccountNumber: e.target.value,
                        })
                      }
                      required
                      placeholder="Enter your bank account number!"
                      className={`${styles.input} mt-2`}
                    />
                  </div>
                  <div className="pt-2">
                    <label>
                       {t('UserName')}
                    </label>
                    <input
                      type="text"
                      name=""
                      required
                      value={bankInfo.bankHolderName}
                      onChange={(e) =>
                        setBankInfo({
                          ...bankInfo,
                          bankHolderName: e.target.value,
                        })
                      }
                      id=""
                      placeholder="Enter your bank Holder name!"
                      className={`${styles.input} mt-2`}
                    />
                  </div>
                  <button
                    type="submit"
                    className={`${styles.button} mb-3 `}
                    style={{
                      background:  theme.palette.mode === "light"
                        ? alpha(theme.palette.primary.main, 0.5)
                        : theme.palette.primary.main,
                    }}
                  >
                    {t('AddNew')}
                  </button>
                </form>
              </Box>
            ) : (
              <>
                <h3 className="text-[22px] font-Poppins">
                  {t('AvailableWithdrawMethods')}
                </h3>

                {seller && seller?.withdrawMethod ? (
                  <div>
                    <div className="800px:flex w-full justify-between items-center">
                      <div className="800px:w-[50%]">
                        <h5 className="m-3"> {t('AccountNumber')}:{" "}
                          {
                            seller?.withdrawMethod?.bankAccountNumber}
                        </h5>
                        <Divider />
                        <h5 className="m-3">{t('UserName')}: {seller?.withdrawMethod?.bankHolderName}</h5>

                        <Divider />
                        <h5 className="m-3">{t('BankName')}: {seller?.withdrawMethod?.bankName}</h5>
                      </div>
                 
                    </div>
                    <br />
                    <h4 className="m-3">{t('AvailableBalance')}: {Balance}  {t('dz')} </h4>
                    <br />
                    <div className="800px:flex w-full items-center">
                      <div
                        className={`${styles.button} mx-3  !h-[42px]`}
                        style={{
                          background:  theme.palette.mode === "light"
                            ? alpha(theme.palette.primary.main, 0.5)
                            : theme.palette.primary.main,
                        }}
                        onClick={withdrawHandler}
                      >
                       {t("Withdraw")}
                      </div>
                      <div
                        className={`${styles.button} mx-3 !h-[42px]`}
                        style={{
                          background: "#A7171A",
                        }}
                         onClick={() => deleteHandler()}
                      
                      >
                       {t("deleteThisOne")}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="text-[18px] pt-2">
                      {t('NoWithdrawMethodsAvailable')}
                    </p>
                    <div className="w-full flex items-center">
                      <div
                        className={`${styles.button}  text-[18px] mt-4`}
                        onClick={() => setPaymentMethod(true)}
                        style={{
                          background: theme.palette.mode === "light"
                            ? alpha(theme.palette.primary.main, 0.5)
                            : theme.palette.primary.main ,
                        }}
                      >
                        {t("AddNew")}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Box>
      )}
    </div>
  );
};

export default WithdrawMoney;
