import React,{useEffect, useState} from "react";
import {LOGO1 ,  algeria,
  USA,
  France,
 } from "../assets";
import useSettings from "../hooks/useSettings";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import ChatIcon from '@mui/icons-material/Chat';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { backend_url } from "../server";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';





const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));



const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const Open = Boolean(anchorEl);
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const { user,seller}= useSelector(state=>state)
  const { t,i18n } = useTranslation();
  const {onChangeDirectionByLang} = useSettings()


  const texts = [t('home'), t('about'), t('docs'), t('pricing')];
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const controlNavbar = () => {
    if (typeof window !== 'undefined') { 
      if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
        setShow(false); 
      } else { // if scroll up show the navbar
        setShow(true);  
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY); 
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);
  

  const handleClose = () => {
    setAnchorEl(null);
  };


  const theme = useTheme()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
     handleClose()
  };

         const list = (anchor) => (
                <Box
                  sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                  role="presentation"
                  onClick={toggleDrawer(anchor, false)}
                  onKeyDown={toggleDrawer(anchor, false)}
                >
            
                  
                  <div className="m-3 block items-center justify-start md:flex-1 lg:w-0">
                  
                              
                  <>
                  {!user?.user?.firstname && !seller?.seller?.firstname ? (
                       <div className="m-3 block items-center justify-start md:flex-1 lg:w-0">
                       <Link to="/auth/login" className="mx-1 mt-3  inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-medium text-white bg-blue-gradient rounded-[10px] outline-none">
                         {t('signIn')}
                       </Link>
                       <Link to="/auth/sellerLogin" className="mx-1 mt-3  inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-medium text-white bg-blue-gradient rounded-[10px] outline-none">
                         {t('get')} {" "}  {t('started')}
                       </Link>
              
                     </div>
             
                  ) : user?.user?.firstname ? (
                  <>
                    <Stack direction="row" spacing={2}>
                      <Link to='/profile'>
                          <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                  >
                  <Avatar alt={user?.user?.firstname} src={`${backend_url}${user?.user?.avatar}`} />
                         </StyledBadge>
                      </Link>
                      <Typography variant="h6" component="h6">
                      {user?.user?.firstname} {user?.user?.lastname}
                       </Typography>
                   </Stack>
                   <Stack direction="row" spacing={2}>
                  
                      <Link to='/chatApp/app'>
                   <Avatar style={{
                                backgroundColor: theme.palette.primary.main,
                                color:'white'
                   }} >
                     <ChatIcon />
                   </Avatar>
                   </Link>
                   <Typography variant="h6" component="h6">
                      {t('shopInbox')}
                       </Typography>
                  
                   </Stack>
                  
                  </>
                  ) : (
                  <>
                  <Stack direction="row" spacing={2}>
                      <Link to='/dashboard'>
                     <StyledBadge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          variant="dot"
                          >
                          <Avatar alt={seller?.seller?.firstname} src={`${backend_url}${seller?.seller?.avatar}`} />
                      </StyledBadge>
                      </Link>
                      <Typography variant="h6" component="h6">
                      {seller?.seller?.firstname} {seller?.seller?.lastname}
                       </Typography>
                  
                  </Stack>
                  <Stack direction="row" spacing={2}>
                   <Link to='/chatApp/app'>
                   <Avatar
                   style={{
                    backgroundColor: theme.palette.primary.main,}} >
                     <ChatIcon />
                   </Avatar>
                   </Link>
                   <Typography variant="h6" component="h6">
                      {t('shopInbox')}
                       </Typography>
                  
                  </Stack>
                  
                  </>
                  )}
                  </>
                  </div>


            <Divider />
            <div className='flex m-3'>
            <Avatar className="mx-2 cursor-pointer" src={France} onClick={ ()=> {changeLanguage('fr');onChangeDirectionByLang("fr") ;window.localStorage.setItem("language", "fr"); }}  />
            <Avatar className="mx-2 cursor-pointer" src={USA} onClick={ ()=> {changeLanguage('en');onChangeDirectionByLang("en") ;window.localStorage.setItem("language", "en");}}  />
            <Avatar className="mx-2 cursor-pointer" src={algeria} onClick={ ()=> {changeLanguage('ar');onChangeDirectionByLang("ar");window.localStorage.setItem("language", "ar"); }}  />
             </div>
        </Box>
      );



  


  return (
    <div >
      {/* This example requires Tailwind CSS v2.0+ */}
      <Box   sx={{
          position: "relative",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#F8FAFF"
              :"#42569E",
          

        }}>
        <div className= {`mx-auto px-4 sm:px-6 ${theme.palette.mode === "light" ? "text-gray-700" : "text-white bg-gray-800"}`}>
          <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start max-w-[250px] overflow-hidden max-h-[65px] lg:w-0 lg:flex-1 lg:ml-10" >
              <Link to="/">
                <img
                  className=" sm-ml-6  h-17  w-[250px] sm:h-17"
                  src={LOGO1}
                  alt=""
             
                />
              </Link>
              <div className="flex flex-row mx-2 mt-2">
   
      </div>            
      </div>

            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className={`rounded-md ${theme.palette.mode === "light" ? "text-gray-700" : "text-white"} p-2 inline-flex items-center justify-center   focus:outline-none focus:ring-2 focus:ring-inse`}
                onClick={toggleDrawer('right', true)}
              >
                <span className="sr-only">Open menu</span>
                {/* Heroicon name: outline/menu */}
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
    
      
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">

            
            <>
            <div className='hidden md:flex mx-3'>
            <Avatar className="mx-2 cursor-pointer" src={France} onClick={ ()=> {changeLanguage('fr');onChangeDirectionByLang("fr") ;window.localStorage.setItem("language", "fr");}}  />
            <Avatar className="mx-2 cursor-pointer" src={USA} onClick={ ()=>{changeLanguage('en');onChangeDirectionByLang("en") ;window.localStorage.setItem("language", "en");}}  />
            <Avatar className="mx-2 cursor-pointer" src={algeria} onClick={ ()=> {changeLanguage('ar');onChangeDirectionByLang("ar");window.localStorage.setItem("language", "ar");}}  />

           </div>

      {!user?.user?.firstname && !seller?.seller?.firstname ? (
        <>
          <Link to="/auth/login" className="whitespace-nowrap mx-4 text-base font-medium">
            {t('signIn')}
          </Link>
        <>
          <Link to="/auth/sellerLogin" className="mx-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-medium text-white bg-blue-gradient rounded-[10px] outline-none">
            {t('get')} {" "}  {t('started')}
          </Link>
        
        </>
        </>
      ) : user?.user?.firstname ? (
        <>
              <Stack direction="row" spacing={2}>
                <Link to='/profile'>
                    <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
                <Avatar alt={user?.user?.firstname} src={`${backend_url}${user?.user?.avatar}`} />
                   </StyledBadge>
                </Link>
                <Link to='/chatApp/app'>
             <Avatar style={{
                          backgroundColor: theme.palette.primary.main,
                          color:'white'
             }} >
               <ChatIcon />
             </Avatar>
             </Link>
             </Stack>

        </>
      ) : (
        <>
           <Stack direction="row" spacing={2}>
                <Link to='/dashboard'>
               <StyledBadge
               overlap="circular"
               anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
               variant="dot"
             >
             <Avatar alt={seller?.seller?.firstname} src={`${backend_url}${seller?.seller?.avatar}`} />
                </StyledBadge>
                </Link>
      
             <Link to='/chatApp/app'>
             <Avatar
             style={{
              backgroundColor: theme.palette.primary.main,}} >
               <ChatIcon />
             </Avatar>
             </Link>
        </Stack>

        </>
      )}
    </>
            </div>
          </div>
        </div>
        <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>

      </Box>
    </div>
  );
};
export default Navbar



