import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";


// create product
export const createProduct = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "productCreateRequest",
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
         authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };
    const { data } = await axios.post(
      `${server}/product/create-product`,
      newForm,
      config
    );
    dispatch({
      type: "productCreateSuccess",
      payload: data.product,
    });
    if(data){
      toast.success("service created successfully")
      window.location.href = '/dashboard';

    }
 
  } catch (error) {


    toast.error(error.response.data.message)
    dispatch({
      type: "productCreateFail",
      payload: error.response.data.message,
    });
  }
};

// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsShopRequest",
    });

    const { data } = await axios.get(
      `${server}/product/get-all-products-shop/${id}`
    );
    dispatch({
      type: "getAllProductsShopSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsShopFailed",
      payload: error.response.data.message,
    });
  }
};

// delete product of a shop
export const deleteProduct = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
       authorization: `Bearer ${localStorage.getItem('token')}`,
      }
  };
  try {
    const  res  = await axios.put(
      `${server}/product/delete/${id}`, {id}  ,config );
    if(res.status === 200){
      toast.success(res.data.message)
      dispatch(getAllProductsShop(res?.data?.sellerId))
    }

  } catch (error) {
    dispatch({
      type: "deleteProductFailed",
      payload: error.response.data.message,
    });
  }
};

// get all products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsRequest",
    });

    const { data } = await axios.get(`${server}/product/get-all-products`);
    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};
