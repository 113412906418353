import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { deleteProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import { Avatar, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaterialTable from "material-table";
import { backend_url } from "../../server";
import {ToastContainer } from "react-toastify";



const AllProducts = () => {
  const { products, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const theme = useTheme();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteProduct(id));
  };
  const {t}= useTranslation()

  const GroupsColumns = [

    {  title: t("image"), maxWidth:"60px" ,field: "images",render:(row)=><Avatar src={`${backend_url}${row.images[0]}`}  /> },
    {  title: t("deal"), field:"name",  },
    {  title: t("status"), field: "state", render: (row)=> <div
    className={`${
      row.state === "waiting_confirmation"
        ? "bg-[#FFCC00]"
        : "bg-green-300"
       } p-2 rounded-md text-center text-gray-900`}
  >
    {row.state}
  </div>
},
    {  title: t("totalPrice"), field:"priceTotal", },
    {  title: t("originalPrice"), field:"originalPrice", },
    {  title: t("sellerPrice"), field:"SellerPrice", },
    {  title: t("WarrantPeriod"), field:"WarrantPeriod", },
    {  title:  t("deliveryTerm"), field:"deliveryTerm", },
    {   title: t("actions"), field:"_id", render:(row)=> <div className='cellAction'> 
                               <Link to={`/product/${row?._id}`}  className="p-2 rounded-md text-center text-gray-900 bg-[#00FF00]"> {t("view")}</Link>
                               <Link className="mx-4 p-2 rounded-md text-center text-gray-900 bg-[#FF0000] " onClick={() => handleDelete(row._id)} >{t("delete")}</Link>
                               </div>},
  ];

  const row = [];

  products &&
    products.forEach((item) => {
      row.push({
        _id: item._id,
        images:item?.images,
        name: item.name,
        priceTotal: item.priceTotal + " " + t("dz"),
        Stock: item.stock,
        deliveryTerm: item?.deliveryTerm,
        originalPrice: item?.originalPrice + " " + t("dz"),
        state: item?.state,
        type: item?.type,
        WarrantPeriod: item?.WarrantPeriod,
        sold: item?.sold_out,
        SellerPrice : item?.SellerPrice + " " + t("dz"),
        

      });
    });

  return (
    <>
    <ToastContainer />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 overflow-x-scroll max-h-[550px]  "> 
             <MaterialTable columns={GroupsColumns} data={row} title={t("latestOrders")}
      options={{exportButton:true,actionsColumnIndex:-1,addRowPosition:"first" , columnsButton:true, }}
      style={{
        backgroundColor:
        theme.palette.mode === "light"
        ? "#F0F4FA"
        : theme.palette.background.paper,
        color:theme.palette.mode === "dark" && "white" ,
        borderRadius:"2rem",
        textAlign:"center"

      }}
  />
        </div>
      )}
    </>
  );
};

export default AllProducts;
