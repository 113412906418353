import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ProductDetails from "../components/Products/ProductDetails";
import { useSelector ,useDispatch } from "react-redux";
import { getAllProductsShop } from "../redux/actions/product";
import { GetCurrentProducts } from "../redux/slices/app";
import { LOGO } from "../assets";
import { Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";


const ProductDetailsPage = () => {
  const { CurrentProduct  } = useSelector((state) => state.app);
  const { id } = useParams();
  const dispatch = useDispatch()
  const {t} = useTranslation()


  useEffect(() => {
    dispatch(GetCurrentProducts(id))
  }, [id]);


  return (
      <>
     {CurrentProduct?.name ? (
  <ProductDetails data={CurrentProduct} />
) : 
<>
<Container sx={{ mt: 5 }} maxWidth="sm">
        <Stack spacing={5}>
          <Stack
            sx={{ width: "100%" }}
            direction="column"
            alignItems={"center"}
          >
            <img style={{ height: 120, width: 120 }} src={LOGO} alt="Logo" />
          </Stack>
          <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
        <Typography variant="h4">{t("dealAwaitingAcceptance")} </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">
            {t('contactUs')} Business@escrowme.net
          </Typography>
        </Stack>
      </Stack>

        </Stack>
      </Container>



</>}
      </>
  );
};

export default ProductDetailsPage;
