import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star  } from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "features",
    title: "Features",
  },
  {
    id: "category",
    title: "category",
  },
  {
    id: "FAQ",
    title: "FAQ",
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Agree to the terms ",
    content:
      "The seller accesses the platform and fill a form of statement about the preset terms and agrees on the platform terms",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Payment & Escrow",
    content:
      "the buyer and seller agree on a preset terms, such as product details and the price...etc.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Delivery",
    content:
      "The buyer follow the link received from the seller and complete the purchase",
  },
  {
    id: "feature-4",
    icon: star,
    title: "Buyer agrees to the product",
    content:
      "The buyer confirms that he has received the product according to the agreed terms.",
  },
  {
    id: "feature-5",
    icon: shield,
    title: "secure payment",
    content:
      "Natvix transfer the payment to the selle  ...etc.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
    name: "abderrahmane saib",
    title: "developer",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "Money makes your life easier. If you're lucky to have it, you're lucky.",
    name: "akram dz",
    title: "seller",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "It is usually people in the money business, finance, and international trade that are really rich.",
    name: "abdellatif",
    title: "Founder & Leader",
    img: people03,
  },
  {
    id: "feedback-4",
    content:
      "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
    name: "abderrahmane saib",
    title: "developer",
    img:people02,
  },

];
export   const faqData = [
  {
    question: "What is the mediation service and how does it work?",
    answer: "It is a service to escrowing deals between the seller and the buyer and acts as a third part to escrow the deal based on the terms agreed upon between the seller and the buyer.. To understand more how the mediation service works, watch the video clip above.",
  },
  {
    question: "How can I register on the platform and create an account?",
    answer: "The registration process on the platform is free and takes place by entering the information represented in: username, phone number and account password.",
  },
  {
    question: "How can I contact the seller and make the purchase?",
    answer: "Communication between the seller and the buyer on the platform is available through the platform’s chat system, but the chat on the platform is a means of communication to complete the transaction and not to search for a seller or buyer.      ",
  },
  {
    question: "What are the available payment methods?",
    answer: "currently! The available payment methods are with the golden card and all Algerian bank cards only.",
  },
  {
    question: "What is the return and refund policy if I am not satisfied with the product?",
    answer: "The “ Escrowme ” platform is not a store for selling products, but rather a means of escrowing deals. The return and refund policy is only if the delivered product is in violation of the agreed terms of the deal.",
  },
  {
      question: "How can I get customer service in case of problems or inquiries?",
      answer: "Escrowme platform includes a direct chat system with platform support in case of problems or inquiries.",
  },
  {
      question: "What guarantees does the platform provide to buyers and sellers?",
      answer: "For buyers, their money is kept on the platform until successful receipt of the product. As for sellers, the process of sending the product takes place after the payment process by the buyer.        ",
  },
  {
      question: "Can I buy and sell on the platform without having to subscribe to the mediation service?",
      answer: "Can I buy and sell on the platform without having to subscribe to the mediation service?        ",
  },

];

export const stats = [
  {
    id: "stats-1",
    title: "User Active",
    value: "3800+",
  },
  {
    id: "stats-2",
    title: "Trusted by seller",
    value: "230+",
  },
  {
    id: "stats-3",
    title: "Transaction",
    value: "$230M+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Content",
        link: "https://www.hoobank.com/content/",
      },
      {
        name: "How it Works",
        link: "https://www.hoobank.com/how-it-works/",
      },
      {
        name: "Create",
        link: "https://www.hoobank.com/create/",
      },
      {
        name: "Explore",
        link: "https://www.hoobank.com/explore/",
      },
      {
        name: "Terms & Services",
        link: "https://www.hoobank.com/terms-and-services/",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Help Center",
        link: "https://www.hoobank.com/help-center/",
      },
      {
        name: "Partners",
        link: "https://www.hoobank.com/partners/",
      },
      {
        name: "Suggestions",
        link: "https://www.hoobank.com/suggestions/",
      },
      {
        name: "Blog",
        link: "https://www.hoobank.com/blog/",
      },
      {
        name: "Newsletters",
        link: "https://www.hoobank.com/newsletters/",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "https://www.hoobank.com/our-partner/",
      },
      {
        name: "Become a Partner",
        link: "https://www.hoobank.com/become-a-partner/",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/profile.php?id=100093843704449",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];