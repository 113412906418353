import React, { useState } from "react";
import styles from "../../styles/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import axios from "../../utils/axios";





const Checkout = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  const [city, setCity] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [zipCode, setZipCode] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const {CurrentProduct} = useSelector((state)=> state.app)
  const [selectedBank, setSelectedBank] = useState('CIB');

  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  };
  const theme = useTheme();
  const navigate = useNavigate()  
  const paymentSubmit = async () => {
    const invoice = {
      user,
      CurrentProduct,
      selectedBank,
      Info:{
        address1,
        address2,
        PhoneNumber:user?.PhoneNumber,
        email:user?.email,
        name: user.firstname + " " + user.lastname,
        selectedBank
      }
    };
    try {
      const response = await axios.post('/webhook', invoice, {
        headers: {
          'Content-Type': 'application/json',
        }
      });  
      window.location.href = response.data;
    } catch (error) {
      toast.error(error?.message)
      console.error('Error creating payment:', error);
    }
  };

 


  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  // this is shipping cost variable


  return (

   <>
    {CurrentProduct._id ?(
    <div className="w-full flex flex-col items-center py-8">
          <ShippingInfo
            user={user}
            city={city}
            setCity={setCity}
            address1={address1}
            setAddress1={setAddress1}
            address2={address2}
            setAddress2={setAddress2}
            zipCode={zipCode}
            selectedBank={selectedBank}
            setZipCode={setZipCode}
            handleBankChange={handleBankChange}
          />
      <div
        className={`${styles.button} w-[150px] 800px:w-[280px] mt-10`}
        style={{background: theme.palette.primary.main}}
        onClick={paymentSubmit}
      >
        <h5 className="text-white">{t("GoToPayment")}</h5>
      </div>
    </div>):
    (<>


    </>)}
   </>
  );
};

const ShippingInfo = ({
  user,
  address1,
  setAddress1,
  address2,
  setAddress2,
  zipCode,
  setZipCode,selectedBank, setSelectedBank,handleBankChange
}) => {
  const theme = useTheme()
  const { t } = useTranslation();

  return (
    <div className="w-full 800px:w-[95%]  rounded-md p-5 pb-8">
      <h5 className="text-[18px] text-center font-[500]">{t('ShippingAddress')}</h5>
      <br />
      <form>
      <div className="mt-2">
            <label className="block pb-2">{t('FullName')}</label>
            <input
              type="text"
              value={user && user.firstname + " " + user.lastname}
              required
              className={`${styles.input} `}
            />
          </div>
       
        <div className="mt-2">
            <label className="block pb-2">{t('EmailAddress')} </label>
            <input
              type="email"
              value={user && user.email}
              required
              className={`${styles.input}`}
            />
          </div>
        <div className="mt-2">
            <label className="block pb-2">{t('PhoneNumber')}</label>
            <input
              type="number"
              required
              value={user && user.PhoneNumber}
              className={`${styles.input} `}
            />
          </div>

        <div className="mt-2">
            <label className="block pb-2">{t("ZipCode")}</label>
            <input
              type="number"
              value={user && user.ZipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
              className={`${styles.input}`}
            />
          </div>
        <div className="mt-2">
            <label className="block pb-2">{t('Address1')}</label>
            <input
              type="address"
              required
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              className={`${styles.input} `}
            />
          </div>
          <div className="mt-2">
            <label className="block pb-2">{t("Address2")}</label>
            <input
              type="address"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              required
              className={`${styles.input}`}
            />
          </div>
    
        <div>
          <label className="block pb-2"  htmlFor="bankSelect">Select a bank:</label>
          <select className="w-[50%]  mt-2 border h-[50px]   border-gray-400 bg-transparent rounded-[10px]" id="bankSelect" value={selectedBank} onChange={handleBankChange}>
            <option value="CIB">CIB</option>
            <option value="EDAHABIA">EDAHABIA</option>
          </select>
        </div>
 
      </form>
    </div>
  );
};


export default Checkout;
