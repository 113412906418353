import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import axios from "../../utils/axios";
import { showSnackbar } from "./app";

// ----------------------------------------------------------------------

const initialState = {
  isLoggedIn: false,
  token: "",
  isLoading: false,
  user: null,
  user_id: null,
  email: "",
  type:"",
  error: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateIsLoading(state, action) {
      state.error = action.payload.error;
      state.isLoading = action.payload.isLoading;
    },
    logIn(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.user_id = action.payload.user_id;
      state.type = action.payload.type;
      state.token = action.payload.token;
    },
    signOut(state, action) {
      state.isLoggedIn = false;
      state.token = "";
      state.user_id = null;
      state.type = "";

    },
    updateRegisterEmail(state, action) {
      state.email = action.payload.email;
    },
  },
});

// Reducer
export default slice.reducer;

export function NewPassword(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    await axios
      .post(
        "/user/reset-password",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        toast.success(response.data.message); 
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (error) {
        toast.error(error.message); 

        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );     
      });
  };
}

export function SellerNewPassword(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    await axios
      .post(
        "/shop/reset-password",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        toast.success(response.data.message); 
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (error) {
        toast.error(error.message); 

        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );     
      });
  };
}


export function ForgotPassword(formValues) {
  return async (dispatch, getState) => {

    await axios
      .post(
        
          "/user/forgetpassword",
          {
            ...formValues,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
       )
      .then(function (response) {

          toast.success( response.data.message)
   
      
      })
      .catch(function (error) {
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
        console.log(error);
        toast.error(error.message)
      });
  };
}

export function SellerForgotPassword(formValues) {
  return async (dispatch, getState) => {

    await axios
      .post(
        
          "/shop/forgetpassword",
          {
            ...formValues,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
       )
      .then(function (response) {
          toast.success( response.data.message)
      })
      .catch(function (error) {
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
        console.log(error);
        toast.error(error.message)
      });
  };
}


export function LoginUser(formValues) {
  return async (dispatch, getState) => {
    // Make API call here
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/user/login-user",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(
          slice.actions.logIn({
            isLoggedIn: true,
            token: response.data.token,
            user_id: response.data.user_id,
            type: "User",
            token:response.data.token,
          })
        );
        dispatch({
          type:"LoadUserSuccess",
          payload:response.data.USER
        })
        window.localStorage.setItem("user_id", response.data.user_id);
        window.localStorage.setItem("type", "user");
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (error) {
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
        toast.error(error.message)

        dispatch(showSnackbar({ severity: "error", message: error.message }));
      });
  };
}
export function LoginSeller(formValues) {
  return async (dispatch, getState) => {
    // Make API call here
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/shop/login-shop",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(
          slice.actions.logIn({
            isLoggedIn: true,
            token: response.data.token,
            user_id: response.data.seller._id,
            type:"Shop",
          })
        );
        dispatch({
          type:"LoadSellerSuccess",
          payload:response.data.seller
        })
        window.localStorage.setItem("user_id", response.data.user_id);
        window.localStorage.setItem("type", "Shop");
        window.localStorage.setItem("token", response.data.token);
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (error) {
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
        console.log(error);
         toast.error(error.message)
        dispatch(showSnackbar({ severity: "error", message: error.message }));
      });
  };
}

export function LogoutUser() {
  return async (dispatch, getState) => {
    window.localStorage.removeItem("user_id");
    window.localStorage.removeItem("type");
    dispatch(slice.actions.signOut());
    dispatch({
      type:"removeAllOrdersShopSuccess"
    })
    dispatch({
      type:"removeAllProductsShopSuccess"
    })
  };
}

export function RegisterUser(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    await axios
    .post(`/user/create-user`, formValues, config)
      .then(function (response) {
        console.log(response)
        dispatch(
          slice.actions.logIn({
            isLoggedIn: true,
            token: response.data.token,
            user_id: response.data.user_id,
            type: "User",
            token:response.data.token,
          })
        );
        dispatch({
          type:"LoadUserSuccess",
          payload:response.data.USER
        })
        window.localStorage.setItem("user_id", response.data.user_id);
        window.localStorage.setItem("type", "user");
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );     
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.message)
        dispatch(showSnackbar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ error: true, isLoading: false })
        );
      })

  };
}
export function RegisterSeller(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    await axios
    .post(`/shop/create-shop`, formValues, config)

      .then(function (response) {
        dispatch(
          slice.actions.updateRegisterEmail({ email: formValues.email })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );

        dispatch(
          showSnackbar({ severity: "success", message: response.data.message })
        );
        toast.success(response.data.message)

      })
      .catch(function (error) {
        toast.error(error.message)
        dispatch(showSnackbar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ error: true, isLoading: false })
        );
      })
      .finally(() => {
        if (!getState().auth.error) {
          window.location.href = "/auth/verify";
        }
      });
  };
}

export function VerifyEmail(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    await axios
      .post(
        "/auth/verify",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(slice.actions.updateRegisterEmail({ email: "" }));
        window.localStorage.setItem("user_id", response.data.user_id);
        dispatch(
          slice.actions.logIn({
            isLoggedIn: true,
            token: response.data.token,
          })
        );


        dispatch(
          showSnackbar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (error) {
        dispatch(showSnackbar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ error: true, isLoading: false })
        );
      });
  };
}
