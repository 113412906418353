import React from "react";
import { AiOutlineLogin, AiOutlineMessage } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineReceiptRefund, HiOutlineShoppingBag } from "react-icons/hi";
import { MdOutlineAdminPanelSettings, MdOutlinePassword, MdOutlineTrackChanges,} from "react-icons/md";
import { TbAddressBook } from "react-icons/tb";
import { RxPerson } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LogoutUser } from "../../redux/slices/auth";
import { useTheme ,alpha} from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";



const ProfileSidebar = ({ setActive, active }) => {
  const navigate = useNavigate();
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const Dispatch = useDispatch()
  const theme = useTheme();
  const { t } = useTranslation();


 const {user} = useSelector((state) => state.user);
  const logoutHandler = () => {
    localStorage.removeItem("token")
    Dispatch({type:"UserLogout"})
    navigate('/')
    LogoutUser()
  };
  return (
    <Box     sx={{
      position: "relative",
      height: "100%",
      backgroundColor:
        theme.palette.mode === "light"
          ? "#F8FAFF"
          : theme.palette.background.paper,
      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",   }}
      className="w-full shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">


     <div
       style={{
        background: active === 1
        ? theme.palette.mode === "light"
          ? alpha(theme.palette.primary.main, 0.5)
          : theme.palette.primary.main : "",
      }}   
      className="w-full flex items-center  rounded-[1rem]  p-4"
      onClick={() => setActive(1)}
      >
        <RxPerson size={20}  color={active === 1 ? "#33bbcf" : ""} />
        <span
          className={`pl-3  800px:block hidden`}
        >
          {t('profile')}
        </span>
      </div>
      <div
       style={{
        background: active === 2
        ? theme.palette.mode === "light"
          ? alpha(theme.palette.primary.main, 0.5)
          : theme.palette.primary.main : "",
      }}   
      className="w-full cursor-pointer flex items-center  rounded-[1rem]  p-4"
        onClick={() => setActive(2)}
      >
        <HiOutlineShoppingBag size={20}  />
        <span
          className={`pl-3  800px:block hidden`}
        >
         {t('orders')}
        </span>
      </div>
      <div
       style={{
        background: active === 5
        ? theme.palette.mode === "light"
          ? alpha(theme.palette.primary.main, 0.5)
          : theme.palette.primary.main : "",
         }}   
      className="w-full flex cursor-pointer items-center   rounded-[1rem]  p-4"
         onClick={() => setActive(5)}
      >
        <MdOutlineTrackChanges size={20}  />
        <span
          className={`pl-3 800px:block hidden`}
        >
          {t("trackOrder")}
        </span>
      </div>
      <div
       style={{
        background: active === 3
        ? theme.palette.mode === "light"
          ? alpha(theme.palette.primary.main, 0.5)
          : theme.palette.primary.main : "",
      }}   
      className="w-full cursor-pointer flex items-center  rounded-[1rem]  p-4"
           onClick={() => setActive(3)}
      >
        <HiOutlineReceiptRefund size={20} />
        <span
          className={`pl-3 800px:block hidden`}
        >
          {t("refunds")}
        </span>
      </div>

      <div
             style={{
              background: active === 4
              ? theme.palette.mode === "light"
                ? alpha(theme.palette.primary.main, 0.5)
                : theme.palette.primary.main : "",
            }}   
            className="w-full cursor-pointer flex items-center  rounded-[1rem]  p-4"

        onClick={() => setActive(4) || navigate("/chatApp/app")}
      >
        <AiOutlineMessage size={20} color={active === 4 ? "#33bbcf" : ""} />
        <span
          className={`pl-3 800px:block hidden`}
        >
          {t("inbox")}
        </span>
      </div>
      <div
       style={{
        background: active === 4
        ? theme.palette.mode === "light"
          ? alpha(theme.palette.primary.main, 0.5)
          : theme.palette.primary.main : "",
      }}   
      className="w-full flex cursor-pointer items-center  rounded-[1rem]  p-4"
         onClick={logoutHandler}
      >
        <AiOutlineLogin size={20}  />
      
        <span
          className={`pl-3 800px:block hidden`}
        >
      {t('Logout')}
        </span>
      </div>
    </Box>
  );
};

export default ProfileSidebar;
