import React from "react";
import { useTranslation } from "react-i18next";
import  getYouTubeID from 'get-youtube-id';
import YouTube from 'react-youtube';


const  VideoComponen = () => {
  const {t} = useTranslation()


  const opts = {
    width:"100%",
    minHeight:"300px",
    playerVars: {
      autoplay: 1,
    },
  };


  return (
    <div className="flex flex-col items-center lg:w-2/3 w-3/3 mx-auto">
    <p className=' font-normal text-[16px] leading-[24px] m-4'> {t('videoExplanation')} </p>
      <div
        className="relative h-0 overflow-hidden max-w-full w-full"
        style={{ paddingBottom: '56.25%' }}
      >
                <YouTube videoId={getYouTubeID("https://www.youtube.com/watch?v=Gbx-HvymI-o", {fuzzy: false})} opts={opts}         className="absolute top-0 left-0 w-full h-full"
 />

      </div>
    </div>
  );
}

export default VideoComponen;
