import React, { useEffect, useState } from "react";
import styles from "../styles/styles";
import Loader from "../components/Layout/Loader";
import ProfileSideBar from "../components/Profile/ProfileSidebar";
import ProfileContent from "../components/Profile/ProfileContent";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const { loading ,isAuthenticated } = useSelector((state) => state.user);
  const [active, setActive] = useState(1);
  const Navigate = useNavigate()




  

  if(!isAuthenticated){
    Navigate('/login')

  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>  
          <div className={`${styles.section} flex  py-10 mt-10`} style={{marginTop:"5rem"}}>
            <div className="w-[50px] 800px:w-[335px] sticky 800px:mt-0 mt-[18%]">
              <ProfileSideBar active={active} setActive={setActive} />
            </div>
            <ProfileContent active={active} />
          </div>
        </>
      )}
    </div>
  );
};

export default ProfilePage;
