import React from "react";
import { Container, Stack, Typography, useTheme } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import {LOGO}  from '../assets'
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { backend_url } from "../server";
import Carousel from "react-multi-carousel";


const Slug = () => {
  const { pages } = useSelector((state) => state.app);
  const [searchParams] = useSearchParams();
  const theme = useTheme()
  function MyComponent(description) {
  
    return <div dangerouslySetInnerHTML={{ __html: description }} />;
  }

  const type= searchParams.get("type")
  
  let page= []
  if(pages){
       page = pages.find((el)=>el.slug  === type)
  }

 
  return (
    <>
      <Container sx={{ mt: 5 , mb: 5 }} >
        <Stack spacing={5}
            sx={{
              backgroundColor:
                theme.palette.mode === "light"
                  ? "#F0F4FA"
                  : theme.palette.background.paper,
        
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
              overflow: "scroll", padding:"1rem"
            }}>

<Carousel
  responsive={{
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }}
  infinite
  autoPlay
  autoPlaySpeed={3000}
>
              {page && page?.banners.map((el , key)=>{
         return ( 
               <Stack
               key={key}
            sx={{ width: "100%" }}
            direction="column"
            alignItems={"center"}
          >
            <img style={{  width: "100%" }} src={`${backend_url}${el}`} alt="Logo" />
          </Stack>)

              })

            }

            </Carousel>

          <Typography paragraph>
            {page && 
            <>
            {MyComponent(page?.description) }
            </>}

          </Typography>
        </Stack>
      </Container>
    </>
  );
};

export default Slug;
