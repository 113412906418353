import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/product";
import {ToastContainer } from "react-toastify";
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, useTheme } from "@mui/material";
import {GetCurrentBenefit} from '../../redux/slices/app'




const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { Categories  } = useSelector((state) => state.category);
  const { success, error,createSuccess } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(GetCurrentBenefit())
  const { CurrentBenefit} = useSelector((state)=>state.app)
  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [SellerPrice, setSellerPrice] = useState(0)
  const [sitePrice, setSitePrice] = useState(0)
  const [originalPrice, setOriginalPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0); 
  const [stock, setStock] = useState("");
  const [deliveryTerm, setDeliveryTerm] = useState("");
  const [WarrantPeriod, setWarrantPeriod] = useState("");
  const [selectedItem, setSelectedItem] = useState("reel");
  const { t} = useTranslation();
  const options = [t('electronics'), t('reel')];
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState(1);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  useEffect(() => {

    if (createSuccess) {
      navigate("/dashboard-products");
      window.location.reload();
    }
  }, [dispatch, error, success,createSuccess]);

  const calculateDiscount = () => {
    const totalPrice = Number(originalPrice) + Number(originalPrice * CurrentBenefit / 100 * selectedOption);
    setDiscountPrice(totalPrice);
  
    const sellerPrice = Number(totalPrice) - Number(originalPrice * CurrentBenefit / 100);
    setSellerPrice(sellerPrice);
  
    const sitePrice = Number(originalPrice * CurrentBenefit / 100);
    setSitePrice(sitePrice);
    };

    
  useEffect(() => {
    calculateDiscount();    
  }, [originalPrice ,selectedOption ]);

  const handleImageChange = (e) => {
    e.preventDefault();
    let files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.append("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("deliveryTerm", deliveryTerm);
    newForm.append("WarrantPeriod", WarrantPeriod);
    newForm.append("type", selectedItem);
    newForm.append("tags", tags);
    newForm.append("originalPrice", originalPrice);
    newForm.append("priceTotal", discountPrice);
    newForm.append("stock", stock);
    newForm.append("SellerPrice", SellerPrice);
    newForm.append("sitePrice", sitePrice);
    newForm.append("shopId", seller._id);
    dispatch(createProduct(newForm))
  };

  return (
    <Box
    sx={{
      backgroundColor:
        theme.palette.mode === "light"
          ? "#F0F4FA"
          : theme.palette.background.paper,

      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
    }} 
    
    className="w-[90%] 800px:w-[50%] shadow h-[85vh]  rounded-[10px] p-4 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">{t('Create Services')} </h5>
      <ToastContainer  ></ToastContainer>
      {/* create product form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
           {t('Name of the service *')} 
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 p-2 appearance-none block w-full px-3 h-[50px] border border-gray-400 bg-transparent rounded-[10px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder={t("Enter your services name...")}
          />
        </div>
        <br />
        <br />
        <div>
          <label className="pb-2">
           {t("type of service*")}
          </label>
          <select className="w-full mt-2 border h-[50px]   border-gray-400 bg-transparent rounded-[10px]"
                  value={selectedItem} onChange={((e)=>setSelectedItem(e.target.value))}>
              <option value="reel">{t("reel")} </option>
              <option value="electronics">{t("electronics")}</option>
            </select>
    

        
        </div>
        <br />
        <div>
          <label className="pb-2">
           {t('Description of the services *')} <span className="text-gradient">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-400 bg-transparent rounded-[10px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t("Enter your service description...")}
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
           {t("Category *")} 
          </label>
          <select
            className="w-full mt-2 border h-[50px]   border-gray-400 bg-transparent rounded-[10px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option className="text-gray-700"  value="Choose a category">{t('Choose a category')} </option>
            {Categories &&
              Categories.map((i) => (
                <option  value={i._id} key={i.name}>
                  {i.name}
                </option>
              ))}
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2">{t("Tags")}</label>
          <input
            type="text"
            name="tags"
            value={tags}
            className="mt-2 p-2 appearance-none block w-full px-3 h-[50px] border border-gray-400 bg-transparent rounded-[10px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTags(e.target.value)}
            placeholder={t("Enter your product tags...")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
           {t("webSiteCost")} 
          </label>
             <select className="w-full mt-2 border h-[50px]   border-gray-400 bg-transparent rounded-[10px]"
                  value={selectedOption} onChange={handleChange}>
              <option value="0">{t("costBySeller")} </option>
              <option value="1">{t("costByClient")}</option>
              <option value="0.5">{t("eachPaysHalf")}</option>
            </select>
        </div>
        <br />


        <br />
        <div>
          <label className="pb-2">{t("Original Price")}</label>
          <input
            type="number"
            name="number"
            value={originalPrice}
            className="mt-2 p-2 appearance-none block w-full px-3 h-[50px] border border-gray-400 bg-transparent rounded-[10px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOriginalPrice(e.target.value)}
            placeholder={t("Enter your product price...")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
         { t("sellerPrice")}
          </label>
          <input
            type="number"
            name="number"
            value={SellerPrice}
            className="mt-2 p-2 appearance-none block w-full px-3 h-[50px] border border-gray-400 bg-transparent rounded-[10px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            readOnly
            placeholder={t("Enter your product price with discount...")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            {t("Price (price total) *")} 
          </label>
          <input
            type="number"
            name="number"
            value={discountPrice}
            className="mt-2 p-2 appearance-none block w-full px-3 h-[50px] border border-gray-400 bg-transparent rounded-[10px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            readOnly
            placeholder={t("Enter your product price with discount...")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
           {t("Product Stock *")} 
          </label>
          <input
            type="text"
            name="text"
            value={stock}
            className="mt-2 p-2 appearance-none block w-full px-3 h-[50px] border border-gray-400 bg-transparent rounded-[10px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setStock(e.target.value)}
            placeholder={t("Enter your product stock...")}
          />
        </div>
        <br />
        <br />
        <div>
          <label className="pb-2">
          {t("delivery Term *")}
          </label>
          <input
            type="text"
            name="delivery Term"
            value={deliveryTerm}
            className="mt-2 p-2 appearance-none block w-full px-3 h-[50px] border border-gray-400 bg-transparent rounded-[10px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDeliveryTerm(e.target.value)}
            placeholder={t("Enter your delivery Term...")}
          />
        </div>
        <br />
        <br />
        <div>
          <label className="pb-2">
          {t('Warrant Period *')} 
          </label>
          <input
            type="text"
            name="WarrantPeriod"
            value={WarrantPeriod}
            className="mt-2 p-2 appearance-none block w-full px-3 h-[50px] border border-gray-400 bg-transparent rounded-[10px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setWarrantPeriod(e.target.value)}
            placeholder={t("Enter your Warrant Period...")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            {t('Upload Images')} <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((i) => (
                <img
                  src={URL.createObjectURL(i)}
                  key={i}
                  alt=""
                  className="h-[120px] w-[120px] object-cover m-2"
                />
              ))}
          </div>
          <br />
          <div>
            <input
              style={{background:theme.palette.primary.main }}
              type="submit"
              value="Create"
              className="my-2 pb-3 mx-auto cursor-pointer appearance-none text-center block w-[150px] p-3 h-[50px] border border-gray-400 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </form>
    </Box>
  );
};

export default CreateProduct;
