import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { backend_url, server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {  Typography, useTheme } from "@mui/material";
import moment from "moment";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {Grid,Divider} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MoneyIcon from '@mui/icons-material/Money';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

const LIST = ({ text, value ,Icon })=>{

  return(
    <ListItem>
      <ListItemAvatar>
           <Icon /> 
      </ListItemAvatar>
      <ListItemText
       sx={{ margin: '0px 16px' }}
       primary={text}
      />
       <ListItemText
       primary={value}
      />
   </ListItem>
  )
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const OrderDetails = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("Transferred");
  const navigate = useNavigate();
  const {t} = useTranslation()
  const theme = useTheme();
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  const [dense, setDense] = useState(false);

    
  const icons = [MoneyIcon, PriceCheckIcon, HourglassTopIcon, AirportShuttleIcon];
  const texts = [t('priceTotal'), t('originalPrice'), t('WarrantPeriod'), t('deliveryTerm') , t("originalPrice") ,
  t('state') , t('seller'), t('firstname'), t('lastname')];



  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const data = orders && orders.find((item) => item._id === id);
  console.log(data)

  const orderUpdateHandler = async (e) => {
    await axios
      .put(
        `${server}/order/update-order-status/${id}`,
        {
          status,
        },
        {
          headers: {
            "Content-Type": "application/json",
             authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        },
      )
      .then((res) => {
        toast.success("Order updated!");
        navigate("/dashboard-orders");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const refundOrderUpdateHandler = async (e) => {
    await axios
    .put(
      `${server}/order/order-refund-success/${id}`,
      {
        status,
      },
      { withCredentials: true }
    )
    .then((res) => {
      toast.success("Order updated!");
      dispatch(getAllOrdersOfShop(seller._id));
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
  }

  return (
    <Box sx={{ flexGrow: 1 }} className={`min-h-[800px] mx-auto max-w-[1400px] my-5`}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
      <Box
          sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? "#F0F4FA"
                : theme.palette.background.paper,
    
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            borderRadius:"30px",
            padding:"1rem",
            textAlign:"center"
          }}>
      <Typography variant="h6" gutterBottom>   
      {t('orderInformation')}

   
    </Typography>
    <Divider />
      </Box>
      <Demo>
                     <List dense={dense} 
                        sx={{
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? "#F0F4FA"
                              : theme.palette.background.paper,
                  
                          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                          borderRadius:"30px",
                          padding:"1rem"
                          ,marginTop:"1rem"
                        }}>
                      <LIST
                       text={t('name')}
                      value = {data.service.name + " " +data.service.name }
                        Icon ={ProductionQuantityLimitsIcon}
                      />
                            <LIST
                       text={t('clientFullName')} 
                      value =      {data?.shippingAddress.name}
                        Icon ={PersonIcon}
                      />
                                 <LIST
                       text={t('phoneNumber')} 
                      value =      {data?.shippingAddress.PhoneNumber }
                        Icon ={PermPhoneMsgIcon}
                      />
                                      <LIST
                       text={t('email')} 
                      value =      {data?.shippingAddress.email }
                        Icon ={MarkEmailReadIcon}
                      />
   
                    <LIST
                       text={t('shippingAddress')} 
                      value =      {data?.shippingAddress.address1 +
                        " " +
                        data?.shippingAddress.address2}
                        Icon ={LocationOnIcon}
                      />
                                           <LIST
                       text={t('paidAt')}
                      value = {moment(data?.paidAt).format('YYYY-MM-DD hh:mm')}
                        Icon ={icons[2]}
                      />
                       <LIST
                       text={t('status')}
                      value = {data?.status}
                        Icon ={icons[2]}
                      />
                          <LIST
                       text={t('userIdea')}
                      value = {data?.userIdea}
                        Icon ={PersonIcon}
                      />
                     </List>
     </Demo>

     
      </Grid>
      <Grid item xs={12} md={6}>
      <Box
          sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? "#F0F4FA"
                : theme.palette.background.paper,
    
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            borderRadius:"30px",
            padding:"1rem",
            textAlign:"center"

          }}>
      <Typography variant="h6" gutterBottom>   
     {t('dealDetail')}

    </Typography>
  
    <Divider />
      </Box>
                   <Demo>
                     <List dense={dense} 
                        sx={{
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? "#F0F4FA"
                              : theme.palette.background.paper,
                  
                          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                          borderRadius:"30px",
                          padding:"1rem"
                          ,marginTop:"1rem"
                        }}>
                               <div className="w-full flex items-start mb-5">
            <img
              src={`${backend_url}/${data.service.images[0]}`}
              alt=""
              className="w-[80x] h-[80px] mx-4"
            />
            <div className="w-full">
              <h5 className="pl-3 text-[20px]">{data.service.name}</h5>
              <h5 className="pl-3 text-[20px] ">
                {data.service.originalPrice} {" "} {t('dz')}
              </h5>
            </div>
          </div>

                      <LIST
                       text={t("name")}
                      value = {data.service?.name}
                        Icon ={icons[0]}
                      />
                          <LIST
                       text={t("originalPrice")}
                      value = {data?.service?.originalPrice                      }
                        Icon ={icons[0]}
                      />
              
                  <LIST
                       text={texts[0]}
                      value = {data?.service?.priceTotal}
                        Icon ={icons[0]}
                      />
                           <LIST
                       text={t('sellerPrice')}
                      value = {data?.service?.SellerPrice}
                        Icon ={icons[0]}
                      />
               
                  
                         <LIST
                        text={texts[2]}
                        value= {data?.service?.WarrantPeriod}
                        Icon ={icons[2]}
                      />

                          <LIST
                        text={t("deliveryTerm")}
                        value= {data?.service?.deliveryTerm}
                        Icon ={LocationOnIcon}
                      /> 
                     </List>
                   </Demo>
      </Grid>
      <Grid item xs={6} md={6}>
      <br />
      <br />
      <h4 className="pt-3 text-[20px] font-[600]">{t('orderStatus')}</h4>
      <select
  value={status}
  onChange={(e) => setStatus(e.target.value)}
  className="w-[50%] px-2 mt-2 border h-[50px] border-gray-400 bg-transparent rounded-[10px]"
>
  <option value="Transferred">{t('transferred')}</option>
  <option value="Shipping">{t('shipping')}</option>
  <option value="Received">{t('received')}</option>
  <option value="OnTheWay">{t('onTheWay')}</option>
  <option value="Delivered">{t('delivered')}</option>
</select>
      <div
        className={`${styles.button} mt-5  !rounded-[4px] font-[600] !h-[45px] text-[18px]`}
        onClick={data?.status !== "Processing refund" ? orderUpdateHandler : refundOrderUpdateHandler}
        style={{background:theme.palette.primary.main }}

      >
        {t('updateStatus')}
      </div>



      </Grid>

    </Grid>
    </Box>
  
  );
};

export default OrderDetails;

/*
  <Box 
    sx={{
      backgroundColor:
        theme.palette.mode === "light"
          ? "#F0F4FA"
          : theme.palette.background.paper,

      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
    }} 
    

    className={`py-4 mt-4 min-h-screen mx-auto w-[90%] 800px:w-[50%] rounded-[30px] p-4`}>
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center">
          <BsFillBagFill size={30}  />
          <h1 className="pl-2 mx-2 text-[25px]"> {t('orderList')}</h1>
        </div>
        <Link to="/dashboard-orders">
          <div
            className={`${styles.button} ! !rounded-[4px] font-[600] !h-[45px] text-[18px]`}
          >
          </div>
        </Link>
      </div>

      <div className="w-full flex items-center justify-between pt-6">
        <h5 className="">
        {t('orderId')} <span>#{data?._id?.slice(0, 8)}</span>
        </h5>
        <h5 className="">
        {t('placedOn')} <span>{moment(data?.paidAt).format('YYYY/MM/DD HH:MM')}</span>
        </h5>
      </div>


      <br />
      <br />
          <div className="w-full flex items-start mb-5">
            <img
              src={`${backend_url}/${data.service.images[0]}`}
              alt=""
              className="w-[80x] h-[80px] mx-4"
            />
            <div className="w-full">
              <h5 className="pl-3 text-[20px]">{data.service.name}</h5>
              <h5 className="pl-3 text-[20px] ">
                {data.service.originalPrice}
              </h5>
            </div>
          </div>
    

      <div className="border-t w-full text-right">
        <h5 className="pt-3 text-[18px]">
         {t('totalPrice')} <strong>{data?.totalPrice}</strong>
        </h5>
      </div>
      <br />
      <br />
      <div className="w-full 800px:flex items-center">
        <div className="w-full 800px:w-[60%]">
          <h4 className="pt-3 text-[20px] font-[600]"> {t('shippingAddress')}</h4>
          <h4 className="pt-3 text-[20px]">
            {data?.shippingAddress.address1 +
              " " +
              data?.shippingAddress.address2}
          </h4>
          <h4 className=" text-[20px]">{data?.shippingAddress.name}</h4>
          <h4 className=" text-[20px]">{data?.shippingAddress.PhoneNumber}</h4>
        </div>
        <div className="w-full 800px:w-[40%]">
          <h4 className="pt-3 text-[20px]">{t('paymentInfo')} </h4>
          <h4>
            {t('status')} {" "}
            {data?.paymentInfo?.status}
          </h4>
        </div>
      </div>
      <br />
      <br />
      <h4 className="pt-3 text-[20px] font-[600]">{t('orderStatus')}</h4>
      <select
  value={status}
  onChange={(e) => setStatus(e.target.value)}
  className="w-[50%] px-2 mt-2 border h-[50px] border-gray-400 bg-transparent rounded-[10px]"
>
  <option value="Transferred">{t('transferred')}</option>
  <option value="Shipping">{t('shipping')}</option>
  <option value="Received">{t('received')}</option>
  <option value="OnTheWay">{t('onTheWay')}</option>
  <option value="Delivered">{t('delivered')}</option>
</select>
      <div
        className={`${styles.button} mt-5  !rounded-[4px] font-[600] !h-[45px] text-[18px]`}
        onClick={data?.status !== "Processing refund" ? orderUpdateHandler : refundOrderUpdateHandler}
        style={{background:theme.palette.primary.main }}

      >
        {t('updateStatus')}
      </div>
    </Box>

*/
