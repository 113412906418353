import React, { useState } from 'react';
import styles, { layout } from '../style';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'; // Import icons from react-icons library
import { Visa} from '../assets';
import { useTheme } from "@mui/material/styles";
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const FAQ = () => {

  const theme = useTheme()
  const [expandedItems, setExpandedItems] = useState([]);

  const { t } = useTranslation();

  const faqData = [
    {
      question: t('qna.0.question'),
      answer: t('qna.0.answer'),
    },
    {
      question: t('qna.1.question'),
      answer: t('qna.1.answer'),
    },
    {
      question: t('qna.2.question'),
      answer: t('qna.2.answer'),
    },
    {
      question: t('qna.3.question'),
      answer: t('qna.3.answer'),
    },
    {
      question: t('qna.4.question'),
      answer: t('qna.4.answer'),
    },
    {
      question: t('qna.5.question'),
      answer: t('qna.5.answer'),
    },
    {
      question: t('qna.6.question'),
      answer: t('qna.6.answer'),
    },
    {
      question: t('qna.7.question'),
      answer: t('qna.7.answer'),
    },
    // Add the remaining items here
  ];

 
  

  const toggleAccordion = (index) => {
    setExpandedItems((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = !prevState[index];
      return updatedState;
    });
  };

  return (

  <section id='FAQ' className={layout.section}>
    <Box   sx={{
          backgroundColor:
            theme.palette.mode === "light"
              ? "#F8FAFF"
              : theme.palette.background,

        }} className={layout.sectionInfo}>
  
      <h2 className={`text-2xl font-Alexandria font-bold mb-4 ${styles.heading2} ${styles.textGradient}`}>
        {t('Frequently Asked Questions')}
      </h2>
      <div className="accordion">
      {faqData.map((item, index) => (
        <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className='font-Cairo'
        >
          <Typography className='font-Cairo'>{item.question}</Typography>
        </AccordionSummary>
        <AccordionDetails className='font-Cairo'>
          <Typography className='font-Cairo'>
          {item.answer}          
          </Typography>
        </AccordionDetails>
      </Accordion>

  

       
        ))}
     
      </div>

    </Box>

    <div className={layout.sectionImg}>
      <img src={Visa} alt="billing" className="w-[100%] h-[100%] max-h-[600px]" />
    </div>
  </section>
 

  );
};

export default FAQ;
/*{faqData.map((item, index) => (
  <div key={index} className={`accordion-item ${styles.accordionItem} max-w-[600px]`}>
    <button
      id={`accordion-button-${index}`}
      aria-expanded={expandedItems[index] ? 'true' : 'false'}
      onClick={() => toggleAccordion(index)}
      className={`w-full flex justify-between items-center text-left py-4 px-6  border-b border-lightgray outline-none ${styles.button}`}
    >
      <span className={`accordion-title ${styles.accordionTitle}`}>{item.question}</span>
      <span
        className={`icon ${expandedItems[index] ? styles.iconRotate : ''}`}
        aria-hidden="true"
      >
        {expandedItems[index] ? <AiOutlineMinus /> : <AiOutlinePlus />}
      </span>
    </button>
    {expandedItems[index] && (
      <div className={`accordion-content px-6 py-4 ${styles.accordionContent}`}>
        <p className={`${styles.paragraph} border-b border-gray-300 max-w-[470px] mt-5`}>{item.answer}</p>
      </div>
    )}
  </div>
))}*/