import React, { useState } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { backend_url, server } from "../../server";
import styles from "../../styles/styles";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { MdTrackChanges } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import {
  loadUser,
  updateUserInformation,
} from "../../redux/actions/user";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { Avatar, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaterialTable from "material-table";
import moment from "moment";


const ProfileContent = ({ active }) => {
  const { t,i18n } = useTranslation();

  const { user, error, successMessage } = useSelector((state) => state.user);
  const [name, setName] = useState(user && user.firstname + " " + user.lastname);
  const [email, setEmail] = useState(user && user.email);
  const [phoneNumber, setPhoneNumber] = useState(user && user.PhoneNumber);
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };

  const theme =useTheme()
  const handleImage = async (e) => {
    const file = e.target.files[0];
    setAvatar(file);

    const formData = new FormData();

    formData.append("image", e.target.files[0]);

    await axios
      .put(`${server}/user/update-avatar`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((response) => {
         dispatch(loadUser());
         toast.success("avatar updated successfully!");
      })
      .catch((error) => {
        toast.error(error);
      });
  };


  return (
    <div className="w-full">
      {/* profile */}
      {active === 1 && (
        <>
          <div className="flex justify-center w-full">
            <div className="relative">
              <img
                src={`${backend_url}${user?.avatar}`}
                className="w-[150px] h-[150px] rounded-full object-cover border-[3px] border-[#3ad132]"
                alt=""
              />
              <div className="w-[30px] h-[30px] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[5px] right-[5px]">
                <input
                  type="file"
                  id="image"
                  className="hidden"
                  onChange={handleImage}
                />
                <label htmlFor="image">
                  <AiOutlineCamera />
                </label>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="w-full px-5">
            <form onSubmit={handleSubmit} aria-required={true}>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('BankName')}</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('emailAddress')}</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t('phoneNumber')} </label>
                  <input
                    type="number"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>

                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">{t("password")} </label>
                  <input
                    type="password"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      )}

      {/* order */}
      {active === 2 && (
        <div>
          <AllOrders />
        </div>
      )}

      {/* Refund */}
      {active === 3 && (
        <div>
          <AllRefundOrders />
        </div>
      )}

      {/* Track order */}
      {active === 5 && (
        <div>
          <TrackOrder />
        </div>
      )}

      {/* Change Password */}
      {active === 6 && (
        <div>
          <ChangePassword />
        </div>
      )}

      {/*  user Address */}

    </div>
  );
};

const AllOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const theme =useTheme()
  const {t}= useTranslation()


  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);
  const GroupsColumns = [
    {  title: t("image"), maxWidth:"60px" ,field: "images",render:(row)=><Avatar src={`${backend_url}${row.images}`}  /> },
  {  title: t("deal"), field:"service",  },
  {  title: t("status"), field: "status", },
  {  title: t("totalPrice"), field:"totalPrice", },
  {  title: t("paidAt"), field:"paidAt", render: (row)=> moment(row.paidAt).format("YYYY MM DD hh:mm") },
  {  title: t("deliveryTerm"), field:"deliveryTerm", },
  {  title: t("WarrantPeriod"), field:"WarrantPeriod", },
  {  title: t("actions"), field:"_id", render:(row)=> <div className='cellAction'> <Link to={`/user/order/${row?._id}`}className={`p-2 rounded-md text-center text-gray-900 bg-[#00FF00]`} >{t("view")}</Link> </div>},
   
];



  const row = [];

  orders && orders.forEach((item) => {
    row.push({
        _id: item._id,
        images:item?.service?.images[0],
        name: item?.service?.name,
        total:  item?.totalPrice+ "DZ ",
        status: item?.status,
        service:item?.service?.name,
        paidAt:item.paidAt,
        deliveryTerm:item?.service?.deliveryTerm,
        WarrantPeriod:item?.service?.WarrantPeriod
        ,totalPrice:item?.totalPrice
      });
  });

  return (
    <div className="w-full mx-8 pt-1 mt-10 ">
      
    <MaterialTable columns={GroupsColumns} data={row} title={t("latestOrders")}
    options={{exportButton:true,actionsColumnIndex:-1,addRowPosition:"first" , columnsButton:true, }}
    style={{
      backgroundColor:
      theme.palette.mode === "light"
      ? "#F0F4FA"
      : theme.palette.background.paper,
      color:theme.palette.mode === "dark" && "white" ,
      borderRadius:"2rem"
    }}
/>
     </div>
  );
};

const AllRefundOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const {t}= useTranslation()

  const theme =useTheme()
  const GroupsColumns = [
    {  title: t("image"), maxWidth:"60px" ,field: "images",render:(row)=><Avatar src={`${backend_url}${row.images}`}  /> },
  {  title: t("deal"), field:"service",  },
  {  title: t("status"), field: "status", },
  {  title: t("totalPrice"), field:"totalPrice", },
  {  title: t("paidAt"), field:"paidAt", render: (row)=> moment(row.paidAt).format("YYYY MM DD hh:mm") },
  {  title: t("deliveryTerm"), field:"deliveryTerm", },
  {  title: t("WarrantPeriod"), field:"WarrantPeriod", },
  {  title: t("actions"), field:"_id", render:(row)=> <div className='cellAction'>   <Link to={`/user/order/${row?._id}`} className={`p-2 rounded-md text-center text-gray-900 bg-[#00FF00]`}>view</Link> </div>},
   
];




  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const eligibleOrders = orders && orders.filter((item) => item.userIdea === "no");


  const row = [];

  eligibleOrders &&
   eligibleOrders.forEach((item) => {
    row.push({
        _id: item._id,
        images:item?.service?.images[0],
        name: item?.service?.name,
        total:  item?.totalPrice+ "DZ ",
        status: item?.status,
        service:item?.service?.name,
        paidAt:item.paidAt,
        deliveryTerm:item?.service?.deliveryTerm,
        WarrantPeriod:item?.service?.WarrantPeriod
        ,totalPrice:item?.totalPrice
      });
  })
  return (
    <div className="w-full mx-8 pt-1 mt-10 ">
      
    <MaterialTable columns={GroupsColumns} data={row} title={t("latestOrders")}
    options={{exportButton:true,actionsColumnIndex:-1,addRowPosition:"first" , columnsButton:true, }}
    style={{
      backgroundColor:
      theme.palette.mode === "light"
      ? "#F0F4FA"
      : theme.palette.background.paper,
      color:theme.palette.mode === "dark" && "white" ,
      borderRadius:"2rem"
    }}
/>
     </div>
  );
};

const TrackOrder = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const theme =useTheme()
  const {t}= useTranslation()



  const GroupsColumns = [
    {  title: t("image"), maxWidth:"60px" ,field: "images",render:(row)=><Avatar src={`${backend_url}${row.images}`}  /> },
  {  title: t("deal"), field:"service",  },
  {  title: t("status"), field: "status", },
  {  title: t("totalPrice"), field:"totalPrice", },
  {  title: t("paidAt"), field:"paidAt", render: (row)=> moment(row.paidAt).format("YYYY MM DD hh:mm") },
  {  title: t("deliveryTerm"), field:"deliveryTerm", },
  {  title: t("WarrantPeriod"), field:"WarrantPeriod", },
  {  title: t("actions"), field:"_id", render:(row)=> <div className='cellAction'>   <Link to={`/user/track/order/${row?._id}`}  className={`p-2 rounded-md text-center text-gray-900 bg-[#00FF00]`}>{t("trackOrder").slice(0,6)}</Link> </div>},
   
];


  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);



  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
          _id: item._id,
          images:item?.service?.images[0],
          name: item?.service?.name,
          total:  item?.totalPrice+ "DZ ",
          status: item?.status,
          service:item?.service?.name,
          paidAt:item.paidAt,
          deliveryTerm:item?.service?.deliveryTerm,
          WarrantPeriod:item?.service?.WarrantPeriod
          ,totalPrice:item?.totalPrice
        });
    })

  return (
    <div className="w-full mx-8 pt-1 mt-10 ">
      
    <MaterialTable columns={GroupsColumns} data={row} title= {t("trackOrder")}
    options={{exportButton:true,actionsColumnIndex:-1,addRowPosition:"first" , columnsButton:true, }}
    style={{
      backgroundColor:
      theme.palette.mode === "light"
      ? "#F0F4FA"
      : theme.palette.background.paper,
      color:theme.palette.mode === "dark" && "white" ,
      borderRadius:"2rem"
    }}
/>
     </div>
  );
};

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const theme =useTheme()

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.success);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  return (
    <div className="w-full px-5">
      <h1 className="block text-[25px] text-center font-[600]  pb-2">
        Change Password
      </h1>
      <div className="w-full">
        <form
          aria-required
          onSubmit={passwordChangeHandler}
          className="flex flex-col items-center"
        >
          <div className=" w-[100%] 800px:w-[50%] mt-5">
            <label className="block pb-2">Enter your old password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Enter your new password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Enter your confirm password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <input
              className={`w-[95%] h-[40px] border border-[#3a24db] text-center text-[#3a24db] rounded-[3px] mt-8 cursor-pointer`}
              required
              value="Update"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};


export default ProfileContent;
