import styles from "../style";
import { discount, robot } from "../assets";
import { useTranslation } from "react-i18next";
import  getYouTubeID from 'get-youtube-id';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;


  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};




const Hero = () => {
  const [open, setOpen] =useState(false);
  const {Video} = useSelector(state=>state.app)
  const opts = {
    width:"100%",
    minHeight:"300px",
    playerVars: {
      autoplay: 1,
    },
  };


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { t } = useTranslation();


  return (
    <section id="home" className={`flex md:flex-row flex-col ${styles.paddingY}`}>
      <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>
        <div className="flex flex-row items-center py-[6px] px-4  rounded-[10px] mb-2">
    
        </div>

        <div className="flex flex-row justify-between items-center w-full">
          <h1 className="flex-1 font-Cairo font-bold ss:text-[60px] text-[30px] gray ss:leading-[100.8px] leading-[75px]">
          {t('theNext')}{" "}
            {t('generation')} {" "}
            {t('ofMediationServices')}
          </h1>
        </div>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
            {t('title')}
          </p>
         <div>

          <div className="w-100 text-center mt-7">

         <Typography onClick={handleClickOpen} className="mx-8 cursor-pointer font-Cairo whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-medium text-white bg-blue-gradient rounded-[10px] outline-none">
           {t("how_it_works")} <PlayCircleOutlineIcon className="m-2 h-4 font-Cairo" />
            </Typography>
          </div>

         </div>

      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
{t("how_it_works")}

 </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
          </Typography>
          <Typography gutterBottom>
          <div
          className="relative  overflow-hidden max-w-full h-[400px] w-[500px]"
          style={{ paddingBottom: '56.25%' }}
          >
                <YouTube videoId={getYouTubeID(Video, {fuzzy: false})} opts={opts}         className="absolute top-0 left-0 w-full h-full"
      />

      </div>
          </Typography>
        </DialogContent>
      </BootstrapDialog>

      <div 
           className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
        <img src={robot} alt="billing" className="w-[100%] h-[100%] relative z-[5]" />
        {/* gradient start */}
        <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40" />
        <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
        {/* gradient end */}
      </div>
    </section>
  );
};

export default Hero;
