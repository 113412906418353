import React from 'react'
import Hero from "../components/Hero";
import styles from '../style';
import { Business,  Footer,  Stats, Testimonials } from '../components';
import FAQ from '../components/Faq';
import { useTheme } from "@mui/material/styles";
import CartCategory from '../components/CartCategory';
import VideoComponen from '../components/VideoComponent';

const HomePage = () => {
  const theme = useTheme()
  return (
    <div>
         <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
      </div>
    </div>
        <div className={`bg-primary ${styles.flexStart}`}>
      <div className={`${styles.boxWidth}`}>
      <Hero />
      </div>
    </div>
    <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
       <div className={`${styles.boxWidth}`}>
        <div className={`${styles.boxWidth}`}>
        <CartCategory />
        </div>
        <Business />       
        <FAQ />
       </div>


    </div>
    
    </div>
  )
}

export default HomePage