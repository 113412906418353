import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { alpha, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import  moment  from "moment"
import ElectricRickshawIcon from '@mui/icons-material/ElectricRickshaw';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ToastContainer } from "react-toastify";
import { UserUpdateOrder } from "../../redux/slices/app";



const TrackOrder = () => {
  const { orders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const theme = useTheme()

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch]);

  const data = orders && orders.find((item) => item._id === id);

  return (
    <>
    <ToastContainer />
    <VerticalTimeline>
          
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{backgroundColor: data.status === "Transferred"
      ? alpha(theme.palette.primary.main, 0.5): theme.palette.mode === "light"
        ? "#F0F4FA"
        : theme.palette.background.paper ,

    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)" }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    
    
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<ElectricRickshawIcon />}
    date ={data.status === "Transferred" && moment(data.updatedAt).format("YYYY MM DD hh:mm")}
  >
    <h3 className="vertical-timeline-element-title">{t('transferred')}</h3>
    <p>
     {t('transferredd')}
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{backgroundColor: data.status === "Shipping"
      ? alpha(theme.palette.primary.main, 0.5): theme.palette.mode === "light"
        ? "#F0F4FA"
        : theme.palette.background.paper ,

    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)" }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    
    
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<ElectricRickshawIcon />}
    date ={data.status === "Shipping" && moment(data.updatedAt).format("YYYY MM DD hh:mm")}
  >
    <h3 className="vertical-timeline-element-title">{t('shipping')}</h3>
    <p>
     {t('en_route')}
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{backgroundColor: data.status === "Received"
      ? alpha(theme.palette.primary.main, 0.5): theme.palette.mode === "light"
        ? "#F0F4FA"
        : theme.palette.background.paper ,

    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)" }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    
    
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<ElectricRickshawIcon />}
    date ={data.status === "Received" && moment(data.updatedAt).format("YYYY MM DD hh:mm")}
  >
    <h3 className="vertical-timeline-element-title">{t('received')}</h3>
    <p>
      {t('receivedd')}
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{backgroundColor: data.status === "OnTheWay"
      ? alpha(theme.palette.primary.main, 0.5): theme.palette.mode === "light"
        ? "#F0F4FA"
        : theme.palette.background.paper ,

    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)" }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    
    
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<ElectricRickshawIcon />}
    date ={data.status === "OnTheWay" && moment(data.updatedAt).format("YYYY MM DD hh:mm")}
  >
    <h3 className="vertical-timeline-element-title">{t('onTheWay')}</h3>
    <p>
      {t('in_transit')}
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{backgroundColor: data.status === "Delivered"
      ? alpha(theme.palette.primary.main, 0.5): theme.palette.mode === "light"
        ? "#F0F4FA"
        : theme.palette.background.paper ,

    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)" }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    
    
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<ElectricRickshawIcon />}
    date ={data.status === "Delivered" && moment(data.updatedAt).format("YYYY MM DD hh:mm")}
  >
    <h3 className="vertical-timeline-element-title">{t('delivered')}</h3>
    <p>
     {t('deliveredd')}
    </p>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
  />

  {(data.status !== "Delivered" && data.userIdea !== "no" && data.userIdea !== "yes") &&
      <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
         {t("ORderIdeaQ")}
        </Typography>
        <Typography variant="body2" color="text.secondary">
         {t("ORderIdea")}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small"onClick={()=>{
          dispatch( UserUpdateOrder({
            id:data._id,
            type:"yes"
          }))

        }} >
          {t("yes")}
        </Button>


        <Button size="small" color="error"
        onClick={()=>{
          dispatch( UserUpdateOrder({
            id:data._id,
            type:"no"
          }))

          
        }}>
          {t("no")}
        </Button>
      </CardActions>
    </Card>
  }

</VerticalTimeline>



    
    
    </>

  );
};

export default TrackOrder;


/*
    <div className="w-full h-[80vh] flex justify-center items-center">
      {" "}
      <>
        {data && data?.status === "paid" ? (
          <h1 className="text-[20px]">Your Order is processing in seller</h1>
        ) : data?.status === "Transferred to delivery partner" ? (
          <h1 className="text-[20px]">
            Your Order is on the way for delivery partner.
          </h1>
        ) : data?.status === "Shipping" ? (
          <h1 className="text-[20px]">
            Your Order is on the way with our delivery partner.
          </h1>
        ) : data?.status === "Received" ? (
          <h1 className="text-[20px]">
            Your Order is in your city. Our Delivery man will deliver it.
          </h1>
        ) : data?.status === "On the way" ? (
          <h1 className="text-[20px]">
            Our Delivery man is going to deliver your order.
          </h1>
        ) : data?.status === "Delivered" ? (
          <h1 className="text-[20px]">Your order is delivered!</h1>
        ) : data?.status === "Processing refund" ? (
          <h1 className="text-[20px]">Your refund is processing!</h1>
        ) : data?.status === "Refund Success" ? (
          <h1 className="text-[20px]">Your Refund is success!</h1>
        ) : null}
      </>
    </div>


*/
