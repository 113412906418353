import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { SelectConversation } from "../../redux/slices/app";
import { socket, connectSocket } from "../../socket";
import {  UpdateDirectConversation,  AddDirectConversation,  AddDirectMessage,} from "../../redux/slices/conversation";
import BottomNav from "./BottomNav";
import styles from "../../style";

const DashboardLayout = () => {
  const isDesktop = useResponsive("up", "md");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {user_id ,type} = useSelector((state) => state.auth);
  
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { conversations, current_conversation } = useSelector(
    (state) => state.conversation.direct_chat
  );

  useEffect(() => {
    if (isLoggedIn) {
      window.onload = function () {
        if (!window.location.hash) {
          window.location = window.location + "#loaded";
          window.location.reload();
        }
      };

      window.onload();

      if (!socket) {
        connectSocket(user_id ,type);
      }

      socket.on("new_message", (data) => {
        const message = data.message;
        if (current_conversation?.id === data.conversation_id) {
          dispatch(
            AddDirectMessage({
              id: message._id,
              type: "msg",
              subtype: message.type,
              message: message.text,
              incoming: type ==="Shop" ? message.seller !== user_id : message.client !== user_id  ,
              outgoing:  type ==="Shop" ? message.seller === user_id : message.client === user_id,
            })
          );
        }
      });

       socket.on("start_chat", (data) => {
         // add / update to conversation list
         const existing_conversation = conversations.find(
           (el) => el?.id === data._id
         );
         if (existing_conversation) {
           dispatch(SelectConversation({ room_id: data._id }));
 
           navigate('/chatApp/app')
         } else if(data.length>0) {
           dispatch(AddDirectConversation({ conversation: data }));
           dispatch(SelectConversation({ room_id: data._id }));
           navigate('/chatApp/app')
         }
       });
    }

    return () => {
      socket?.off("start_chat");
      socket?.off("new_message");
    };
  }, [isLoggedIn, socket]);

  if (!isLoggedIn) {
    return <Navigate to={"/auth/login"} />;
  }

  return (
    <>
       <Stack direction="row">
      {isDesktop ?(
          <SideNav />
        ):(
          <BottomNav />
          )}
        <Outlet />
      </Stack>
   
    </>
  );
};

export default DashboardLayout;
